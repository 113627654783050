import CarouselCat from '../components/Carousel';
import { Link } from 'react-router-dom';

function ThankYouPage() {
  return (
    <div>
      <CarouselCat category='' species='birds' legend='Thank you for your submission' />
      <div className='frontpagetext'>
        <div className='frontpagetext-inner'>
        <p>
          If you would like to add more sightings please navigate back to the <Link to='/birds/sightings' onClick={() => {window.scroll(0, 0);}}>submissions page</Link>.
          Otherwise feel free to have a look through what other birds have been sighted <Link to='/birds/view' onClick={() => {window.scroll(0, 0);}}>here</Link>.
        </p>
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;
