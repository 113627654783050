import ImageScene from "../../components/360PhotoViewer";
import { useNavigate } from "react-router-dom";

function FarmhousePano() {
    const imagePath = 'https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/360%20Footage%2Fhouseabove.JPG?alt=media&token=fd32c748-9334-4f65-9477-bf2695514d2b'; // Replace with your image path
    
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `/camera/modernday`; 
      navigate(path);
      window.location.reload();
    }

return (
    <div>
    <div style={{ position: 'relative' }}>
        <ImageScene imagePath={imagePath} />
        <button onClick={routeChange}
            style={{
                position: 'absolute',
                right: '20px',
                background: 'transparent',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
                color: 'white', 
            }}
        >
            X
        </button>
    </div>
</div>
)
}

export default FarmhousePano;