import { menuItems } from "./menuItemsFooter"
import { Link } from 'react-router-dom';
import './footer.css'

const FooterMob = () => {
    return (
    <div className="mobfoot">
        <h2> Links </h2>
        <div className="small_gap"/>
    <div className="navmob">
        {menuItems.map((menu) => (
            <button className='button'
            type="button"
            aria-haspopup="menu"
            >
            <a href={menu.url}>{menu.title}</a>
                <span
                className={
                    menu.url ? " custom" : ""
                }
                />
            </button>
        ))}
    </div>
    <div className="contactmob">
        <h2> Contact us </h2>
        <div className="small_gap"/>
        <h3>Find us in:</h3>
        <h4>Dorchester-on-Thames, Oxfordshire</h4>
        <div className='small_gap' />
        <h3>By email:</h3>
        <a href='mailto:izzy@bishopscourtfarm.com/' >
            <h4>izzy@bishopscourtfarm.com</h4>
        </a>           
        <div className='small_gap' />
        <h3>For our main website please go to:</h3>
        <Link to='https://www.bishopscourtfarm.com/' >
            <h4>bishopscourtfarm.com</h4>
        </Link>
    </div>
    <div className="medium_gap" />
    <div className="navbarlogo" >
    <img src="/logo/BCF_Logo.webp" width='100rem' />
    </div>
    </div>
    )
}

export default FooterMob; 