import HistoryMap from "../components/HistoryMaps"
import CarouselCat from '../components/CarouselOther';
import PhotoGal from "../components/PhotoGal";
import { landscapeDesktop, landscapeMobile } from '../db/public_images';

function HistoryMapDisplay () {
    let jsonData = landscapeMobile
    if (window.screen.availWidth > 768) {
      jsonData = landscapeDesktop
    }
    return (
        <div>
            <CarouselCat imageList = {jsonData} legend= "Maps of the farm and village" />
            <div className="frontpagetext"> 
              <div className='frontpagetext-inner' >
                <p>
                    We think the history of the farm and the village and how they have changed over time is really interesting. We developed 
                    a tool where you can see where you are standing today and what part of the farm that might have been in a different time! 
                    If you are wandering around the farm, keep an eye on the map below and see what the field you are on would have been in 
                    the past. 
                </p>
                <p>
                    This map shows how Dorchester looked in the 1840s. 
                </p>
                </div>
            </div>
                <HistoryMap /> 
                <div className="frontpagetext">
                <div className='frontpagetext-inner' >
                    <h1>
                        Early 20th Century Maps
                    </h1>
                    <div className='small_gap' />
                    <p> 
                        If you zoom in and out of the map below it will take you through maps of different scales from the early 20th 
                        century in the UK. When you zoom in closely you will be able to see the farm and the village in detail. 
                    </p>
                    <p>
                        They are all ordnance survey maps that vary in scale. The smallest scale is 1:10,560 from 1888-1913, next is 1:63,360 from 1885-1903,
                        then 1:253,440 from 1905, and finally 1:1 million from 1905. 
                    </p>
                </div>
                </div>
                <div className="history_map" >
                    <iframe 
                        src='https://api.maptiler.com/tiles/uk-osgb1888/?key=NO4pOFYHQ9fnqu9uMc8U#15.056658835882903/51.642/-1.169'
                        style={{ width: '100%', height: '100%' }}
                    />
                </div>
                <div className="small_gap" />
            <div className="frontpagetext"> 
            <div className='frontpagetext-inner' >
                <p>
                    Even going back hundreds of years a lot of the land around Dorchester has changed very little. The footpaths have been 
                    in the same places for extremely long periods of time. For example, the path beside the lakes towards the Dyke Hills has 
                    been there since the Iron Ages around 3000 years ago. Several other roads around Dorchester were built by the Romans 2000 
                    years ago. Whilst we do not have maps available for all of these periods, it is fascinating to think about what a 
                    history Dorchester has had and what evidence we can still see of it today. 
                </p>
                <p> 
                    You may have noticed on several maps that Bishop's Court is on the site of what was Bishop's Palace. It is believed 
                    there was a palace on the site of the farm house. This dated to the time of Dorchester being a cathedral town many 
                    centuries ago, and would have been the home of the bishop. 
                </p>
                <div className="medium_gap" />
                <h1>Have a look at some of the other old maps we have found! </h1>
                <div className="small_gap" />
                <p>
                    We have found a range of old maps dating from the 16th century through to the 20th century. Some are closeups of 
                    the village - you can see from the 1960s map that the gravel pits were only partially dug at that point. Others are 
                    larger scale maps covering the whole county and beyond.
                </p>
            </div>
            </div>
            <PhotoGal link={'https://farm-website-backend-6924ec883f42.herokuapp.com/history?type=map'} />
        </div>
    )
}

export default HistoryMapDisplay;