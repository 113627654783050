import React, { useEffect, useState } from 'react';
import '../App.css'
import CarouselCat from '../components/CarouselOther';
import { Link } from "react-router-dom";
import CallSpeciesPhoto from "../components/CallSpeciesPhoto";
import { farmanimalsDesktop, farmanimalMobile } from '../db/public_images';


function FarmAnimals() {
    let jsonData = farmanimalMobile
    if (window.screen.availWidth > 768) {
      jsonData = farmanimalsDesktop
    }
    
  return (
    <div>
      <CarouselCat imageList={jsonData} legend= "Farm Animals!" />
      <div className = "frontpagetext">
      <div className='frontpagetext-inner' >
      <p> 
        We have a wide range of farm animals, 6 breeds of sheep, 2 breeds of alpacas, 3 breed of pigs, horses, and 2 breeds of goat. 
        On top of this we also have bee hives and some farm cats to keep the rat numbers down!
      </p>
      <div className="small_gap" />
      <p>
        Farms are important across the world, they provide food, clothing materials, and can be beneficial for the environment 
        and biodiversity if they are well managed. On our farm we harvest wool from the sheep and the alpacas, we also get honey 
        from the bees, and meat from the pigs and sheep. We then sell these products in our farm cafe or trade them with other 
        local farms and businesses, which contributes to the local economy. 
      </p>
      <div className="small_gap" />
      <p> 
        To learn more about the animals we keep on the farm, and how to identify all the different breeds, have a look through the 
        links below.
      </p>
      </div>
      </div>
        <div className="trapLinks">
        <div className="imageContainer">
            <Link to="/farm_animals/sheep" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='sheep_redirect' species='' width='356px' />
            <div className="bannersmall">
                <h4>Sheep</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/goats" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='goat_pygmy_baby_redirect' species='' width='356px' />
            <div className="bannersmall">
                <h4>Goats</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/pigs" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='pig_bob_redirect' species='' width='356px' />
            <div className="bannersmall">
                <h4>Pigs</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/alpacas" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='alpaca_redirect' species='' width='356px' />
            <div className="bannersmall">
                <h4>Alpacas</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/horses" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='horse_sunset_redirect' species='' width='356px' />
            <div className="bannersmall">
                <h4>Horses</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/bees" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='beehives_redirect' species='' width='356px' />
            <div className="bannersmall">
                <h4>Bees</h4>
            </div>
            </Link>
        </div>
      </div>
  </div>
  );
};

export default FarmAnimals;
