import React, { useEffect, useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";


type Data = {
  id: string;
  name: string;
  width: number;
  height: number;
  src: string;
  description: string;
};

function PhotoGal ({link}) {
    const [data, setData] = useState<Data[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [index, setIndex] = useState(-1);
    const captionsRef = React.useRef(null);

  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(link);
          const jsonData = await response.json();
          console.log('Fetched data:', jsonData);
          setData(jsonData);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (isLoading) {
      return <p>Loading...</p>;
    }
  
    return (
      <>
        <PhotoAlbum
          photos={data}
          layout="rows"
          targetRowHeight={150}
          spacing={0}
          padding={2}
          onClick={({ index }) => setIndex(index)}
        />
  
        <Lightbox
          slides={data}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          // enable optional lightbox plugins
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Captions]}
          captions={{ ref: captionsRef }}
        />
      </>
    );
  };
export default PhotoGal;
