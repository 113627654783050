import '../../App.css'
import { Link } from "react-router-dom";
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import CarouselCat from "../../components/CarouselOther";
import VideoGal from "../../components/VideoGal";
import { birdsDesktop, birdsMobile } from '../../db/public_images';

function Birdlife() {
    let jsonData = birdsMobile
    if (window.screen.availWidth > 768) {
      jsonData = birdsDesktop
    }
    return (
        <div>
            <CarouselCat imageList={jsonData} legend='Want to learn about birds?' />
            <div className= "frontpagetext" >
            <div className='frontpagetext-inner' >
                <p>
                    Many species of birds come to nest or feed on the farm. We aim to provide lots of habitats for our bird 
                    populations to improve diversity. We have increased our wetland coverage by digging scrapes which fill with water
                    for birds to feed in, as a variety of wetland birds use the farm for feeding. We are also putting increased protections
                    around some areas of the farm which are known to have ground nesting birds, to reduce disturbance. 
                </p>
                <div className='small_gap'/>
                <p>
                    Around our wetlands and lakes we have plenty of ducks, geese, swans, and gulls. We have also sighted some 
                    species around, including wigeons, sandpipers and plovers. The farm in general is home to many breeding species as well. 
                    These include great crested grebes, tufted ducks, little owls, and stock doves, as well as swallows.
                </p>
                <div className="medium_gap" />
                <div className="columnContainer">
                    <div className="columnonelarge">
                        <h1>
                            Swallows
                        </h1>
                        <div className="small_gap" />
                        <p>
                            In many of our barns we have swallows nesting for the summer months. Swallows are migratory birds, spending 
                            summer in the UK and elsewhere in the northern hemisphere to breed, before flying down to Africa from 
                            September time where there is more food during winter. They tend to come back every year to the same nests 
                            which they make out of mud tied together with dried grass. 
                        </p>
                        <div className="small_gap" />
                        <p> 
                            During their summer in the UK they can have up to three sets of fledglings, each with up to five eggs. They 
                            tend to have fewer chicks in their later sets of the season. In the 
                            daytime the parents leave the nest to forage for insects, their primary food source, returning around dusk 
                            to feed their chicks. 
                        </p>
                    </div>
                    <div className="columntwo">
                        <div className="gap">
                        </div>
                    <CallSpeciesPhoto name='swallow3' species='' width='100%' />
                    </div>
                </div> 
                <div className="small_gap" />
                <div className="columnContainer">
                    <div className="columnonesmall">
                        <CallSpeciesPhoto name='redkite1' species='' width='100%' />
                    </div>
                    <div className="columntwo">
                    <h1>
                        Birds of prey 
                    </h1>
                    <div className="small_gap" />
                    <p>
                        If you have been to the farm or are from around Oxfordshire, you will be familiar with our red kites. These 
                        scavengers are rampant around Oxfordshire after being reintroduced over 30 years ago. Prior to that they had 
                        almost gone extinct in the UK. Their return is a fantastic example of rewilding, to improve our ecosystems and 
                        biodiversity. Kites feed primarily on 
                        carrion from road kills or other dead animals they find around the farm. They also occasionally hunt for prey 
                        such as small birds and rodents. 
                    </p>
                    </div>
                </div>
                <div className="small_gap" />
                <p>
                    Other birds of prey that we have spotted around the farm include kestrels, buzzards, peregrine falcons, hobbies, and sparrow 
                    hawks. So keep an eye out for anything exciting!
                </p>
                <div className='medium_gap' />
                <h1> 
                    Camera Trap Gallery
                </h1>
            </div>
            </div>
                <VideoGal species="birds"/>   
            <div className="frontpagetext" >
            <div className='frontpagetext-inner' >
                <h1> 
                    Want to learn more?
                </h1>
                <div className="small_gap" />
                <p> 
                    To continue learning about our different wildlife please continue with the links below:
                </p>
            </div>
            </div>
            <div className="trapLinks">
                <div className="imageContainer">
                    <Link to="/wildlife/badgers" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='badger_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Badgers</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/foxes" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='fox_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Foxes</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/deer" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='deer_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Deer</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/rabbits" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='rabbit_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Rabbits</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/otherspecies" onClick={() => {window.scroll(0, 0);}}>
                        <img
                        src="https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/DSCF0035.JPG?alt=media&token=423f367b-3173-4047-88ca-a3b825cf9b08.png"
                        height={100}
                        alt="Other Species"
                        />
                    <div className="bannersmall">
                        <h4>Other Species</h4>
                    </div>
                    </Link>
                </div>
            </div>
        </div>    
    );
}

export default Birdlife;