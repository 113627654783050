
import React, { useEffect } from 'react';
import 'aframe';
import 'aframe-particle-system-component';
import {Entity, Scene} from 'aframe-react';

function ImageScene ({imagePath}) {

    return (
      <Scene>
        <a-assets>
          <img id="image" src={imagePath} alt="3D Image" />
        </a-assets>
        <a-sky src="#image"></a-sky>
        <a-camera position="0 1.6 0">
          <a-cursor></a-cursor>
        </a-camera>
      </Scene>
    );
  };


export default ImageScene;
