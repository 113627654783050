import { useEffect, useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Video from "yet-another-react-lightbox/plugins/video";

type Data = {
  id: string,
  url?: string,
  src: string,
  species?: string,
  height: number,
  width: number,
  poster?: string
  sources?: {src: string, type: string}[]
};


function VideoGal({species}) {
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/cameratraps?species=' + species);
        const jsonData = await response.json();
        const modifiedData = jsonData.map((item: { filetype: string; }) => {
          if (item.filetype === "mp4") {
            return { ...item, type: "video" as const };
          }
          return item;
        });
        

        console.log('Fetched data:', modifiedData);
        setData(modifiedData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [species]);



  if (isLoading) {
    return <p>Loading...</p>;
  }



  return (
    <>
    <PhotoAlbum
      photos={data}
      layout="rows"
      targetRowHeight={150}
      spacing={0}
      padding={2}
      onClick={({ index }) => setIndex(index)}
    />

    <Lightbox
      slides={data}
      open={index >= 0}
      index={index}
      close={() => setIndex(-1)}
      // enable optional lightbox plugins
      plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
    />
  </>
  );
}

export default VideoGal;
