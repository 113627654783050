import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CarouselCat from '../../components/CarouselOther';
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import { pigsDesktop, pigsMobile } from "../../db/public_images";

function Pigs() {
    let jsonData = pigsMobile
    if (window.screen.availWidth > 768) {
      jsonData = pigsDesktop
    }
    return (
        <div>
        <CarouselCat imageList={jsonData} legend= "Want to learn about pigs?" />
        <div className= "frontpagetext" >
        <div className='frontpagetext-inner' >
            <p> 
                We have three breeds of pigs on the farm. Each of these have different benefits, some by producing larger litters of piglets,
                others by producing better quality meat. We sometimes cross breed our different pigs to get the highest of all the benefits 
                and produce large litters of hardy pigs that we can use for meat. If you have seen our pigs you may have noticed they do not 
                all look like the breed photos, these are likely the ones that have been cross-bred to produce the best offspring. 
            </p>
            <div className="small_gap" />
            <p>
                Our pigs all live outside as they are hardy breeds made to withstand the weather. They are mostly situated down in pens in 
                the wildflower meadow, though some of our younger boys are more central in 
                the farm. If you live in the village you may have noticed some of these youngsters getting out and going on adventures around 
                the village. Pigs can be quite smart and cunning in their abilities to break free! 
            </p>
            <div className="medium_gap" />
            <div className="speciesImages"> 
                <div className="goatcolumn">
                    <CallSpeciesPhoto name='pig_osb1_crop' species='' width='230' />
                    <div className="small_gap" />
                    <h4> Oxford Sandy and Black</h4>
                    <ul className='list3col'>
                    <li> <p>Distinct black and orange patterned bodies. </p></li>
                    <li> <p>Produce very high quality meat with low fat content. </p></li>
                    <li> <p>Natural forager and suited to outside habitats. </p></li>
                </ul>
                </div>
                <div className="goatcolumn">
                    <CallSpeciesPhoto name='pig_saddleback_crop' species='' width='240' />
                    <div className="small_gap" />
                    <h4> Saddleback </h4>
                    <ul className='list3col'>
                    <li> <p>Black body with white band around the middle (saddle) and white feet. </p></li>
                    <li> <p>Produce an average of nearly 10 piglets per litter. </p></li>
                    <li> <p>Produce good meat for pork or bacon. </p></li>
                </ul>
                </div>
                <div className="goatcolumn">
                    <CallSpeciesPhoto name='pig_white_crop' species='' width='240' />
                    <div className="small_gap" />
                    <h4> Large White </h4>
                    <ul className='list3col'>
                    <li> <p>Large pigs that take a long time to mature. </p></li>
                    <li> <p>Produce large litters and lots of milk, strong maternal instincts. </p></li>
                    <li> <p>Not good for meat. </p></li>
                </ul>
                </div>
            </div>
            <div className="medium_gap" />
            <h1> 
                Want to learn more?
            </h1>
            <div className="small_gap" />
            <p> 
                To continue learning about our different farm animals please continue with the links below:
            </p>
        </div>
        </div>
        <div className="trapLinks">
        <div className="imageContainer">
            <Link to="/farm_animals/sheep" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='sheep_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Sheep</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/goats" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='goat_pygmy_baby_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Goats</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/alpacas" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='alpaca_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Alpacas</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/horses" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='horse_sunset_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Horses</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/bees" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='beehives_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Bees</h4>
            </div>
            </Link>
        </div>
        </div>
    </div>
    );
}

export default Pigs;