export const menuItems = [
    {
      title: <h3>Home</h3>,
      url: '/',
    },
    {
      title: <h3>Farm animals</h3>,
      url: '/farm_animals',
    },
    {
      title: <h3>Wildlife</h3>,
      url: '/wildlife',
    },
    {
      title: <h3>Bird Sightings</h3>,
      url: '/birds/sightings',
      },
      {
      title: <h3>Gallery</h3>,
      url: '/gallery',
      },
      {
      title: <h3>360 Footage</h3>,
      url: '/camera/videoviewers',
      },
      {
        title: <h3>History</h3>,
        url: '/history',
      },
  ];