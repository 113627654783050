

import React, { useState, useEffect, useRef } from "react";
import Feature from 'ol/Feature.js';
import Map from 'ol/Map.js';
import Point from 'ol/geom/Point.js';
import View from 'ol/View.js';
import {Fill, Icon, Stroke, Style} from 'ol/style.js';
import {OGCMapTile, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {fromLonLat} from 'ol/proj.js';
import TileJSON from 'ol/source/TileJSON.js';
import Location from "./CurrentLocation";
import Geolocation from 'ol/Geolocation.js';
import CircleStyle from "ol/style/Circle";
import { Coordinate } from "ol/coordinate";

function MapWrapper(props: any) {
  const currentLocation = Location();
  const [map, setMap] = useState<any>();
  const mapElement = useRef<any>();
  const [pointFeature, setPointFeature] = useState<Feature | null>(null); // State for the Point feature
  const [center, setCenter] = useState(fromLonLat([-1.169, 51.642]));
  const [coordinates, setCoordinates] = useState<Coordinate | undefined>(undefined);
  
  const source = new TileJSON({
      url: `https://api.maptiler.com/tiles/uk-osgb1888/tiles.json?key=NO4pOFYHQ9fnqu9uMc8U`, // source URL
      tileSize: 512,
      crossOrigin: 'anonymous'
    });

    const view = new View({
      center: center,
      zoom: 17,
    })

  
    if ('geolocation' in navigator) {
      const geolocation = new Geolocation({
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: view.getProjection(),
      });
    
      geolocation.on('change', () => {
        const newCoordinates = geolocation.getPosition();
        setCoordinates(newCoordinates);
      });
    
      geolocation.setTracking(true);
    }

  useEffect(() => {
    let dorchester = new Feature();
    if (currentLocation) {
      dorchester = new Feature({
        geometry: new Point(fromLonLat([currentLocation.longitude, currentLocation.latitude])),
      });
    }
    dorchester.setStyle(
    new Style({
        image: new Icon({
        crossOrigin: 'anonymous',
        src: "https://docs.maptiler.com/openlayers/default-marker/marker-icon.png",
        anchor: [0.5, 1],
        }),
    })
    );
    
    setPointFeature(dorchester)

    const vectorSource = new VectorSource({
        features: [dorchester],
      });
      
    const vectorLayer = new VectorLayer({
    source: vectorSource,
    });
  
  const positionFeature = new Feature({
    
  });
  positionFeature.setStyle(
    new Style({
      image: new Icon({
      crossOrigin: 'anonymous',
      src: "https://docs.maptiler.com/openlayers/default-marker/marker-icon.png",
      anchor: [0.5, 1],
      }),
      zIndex: 100
  })
  );

  console.log(coordinates)

  if (coordinates) {
    positionFeature.setGeometry(new Point(coordinates));
  } else {
    positionFeature.setGeometry(undefined);
  }
  
  
  const positionLayer = new VectorLayer({
    source: new VectorSource({
      features: [positionFeature],
    }),
  });


  new Map({
    target: mapElement.current,
    layers: [
      positionLayer,
      new TileLayer({
        source: source,
      }),
      vectorLayer
    ],
    view: view
    
  });



  }, [currentLocation, coordinates]);
  

  

  return (
    <div className="mapRow">
      <div ref={mapElement} className="map-container" />
    </div>
  );
}

export default MapWrapper;