import React, { useEffect, useState } from "react";

type Data = {
    category?: string, 
    src?: string,
    name?: string,
    species?: string, 
    height?: number,
    width?: number,
}

function CallSpeciesPhoto ({name, species, width}) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<Data[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let jsonData = []
                if (name) {
                    const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/speciesphotos?name=' + name);
                    jsonData = await response.json();
                } else if (species) {
                    const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/speciesphotos?species=' + species);
                    jsonData = await response.json();
                }

                console.log('Fetched data:', jsonData);
                setData(jsonData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [name]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            {data.map((item) => (
                <img src={item.src} width={width} />
            ))}
        </div>
    )
}

export default CallSpeciesPhoto; 