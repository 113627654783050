import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import {BiSolidLeftArrow} from "react-icons/bi"

const SidebarMob: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [activeSection, setActiveSection] = useState<string | null>(null);


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 100; // Adjust this value to match your header's height
      const carouselHeight = 300; // Adjust this value to match your carousel's height
      const scrollTop = window.scrollY;

      // Check if the user has scrolled past the header and carousel
      if (scrollTop > headerHeight + carouselHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }

      // Determine the active section based on scroll position
      const sections = ['thestoneage', 'theironage', 'theromans', 'thesaxons', 'medievalperiod', 'recenthistory']; // Add your section IDs here
      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        const sectionElement = document.getElementById(section);
        if (sectionElement && sectionElement.offsetTop <= scrollTop) {
          setActiveSection(section);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sidebarStyleMob: React.CSSProperties = {
    position: 'fixed',
    top: '50%', // Position halfway down the screen
    right: isSidebarOpen ? '0' : '-220px', // Adjust the width as needed
    transform: 'translateY(-50%)', // Vertically center the sidebar
    width: '220px',
    backgroundColor: '#003a70',
    color: '#c2e5a6',
    padding: '20px',
    zIndex: 1,
    transition: 'right 0.3s ease',
    paddingTop: '20px'
};

  const arrowStyle: React.CSSProperties = {
    position: 'fixed',
    top: '50%', // Position halfway down the screen
    right: '10px', // Adjust the right position as needed
    transform: 'translateY(-50%)', // Vertically center the arrow
    cursor: 'pointer',
    zIndex: 2, // Ensure the arrow is above the sidebar
    color: '#c2e5a6',
  };

return (
    <div className='sidebarmob' >
      <div style={arrowStyle} onClick={toggleSidebar}>
        <BiSolidLeftArrow size={30} />
      </div>
    <div style={sidebarStyleMob}>
    <h3> Jump to:</h3>
    <ul>
    <li>
        <Link 
        to="introduction" >
        <h5>Introduction</h5>
        </Link>
    </li>    
    <li>
        <Link 
        to="thestoneage" >
        <h5>The Stone Age</h5>
        </Link>
    </li>
    <li>
        <Link 
        to="theironage" 
        >
        <h5>The Iron Age</h5>
        </Link>
    </li>
    <li>
        <Link 
        to="theromans" 
        >
        <h5>The Romans</h5>
        </Link>
    </li>
    <li>
        <Link 
        to="thesaxons" 
        >
        <h5>The Saxons</h5>
        </Link>
    </li>
    <li>
        <Link 
        to="medievalperiod" 
        >
        <h5>Medieval Period</h5>
        </Link>
    </li>
    <li>
        <Link 
        to="recenthistory"
        >
        <h5>Recent History</h5>
        </Link>
    </li>
    <li>
        <Link 
        to="historygallery"
        >
        <h5>Gallery</h5>
        </Link>
    </li>
    </ul>
    </div>
    </div>
);
}

export default SidebarMob;