import VideoGal from "../components/VideoGal";
import { Link } from "react-router-dom";
import CarouselCat from "../components/Carousel";

function GalleryCameraTraps() {
    return (
        <div>
            <CarouselCat category='wildlife' species='' legend='Camera Trap Gallery' />
        <div className='frontpagetext'>
        <div className='frontpagetext-inner'>
          <p> 
            If you spend a lot of time wandering around the farm, you may have noticed some of the camera traps we have dotted around. 
            We use these to keep an eye on our wildlife and see what range of animals we have coming through and living on the farm. 
            Mostly we see mammals, such as deer, badgers, foxes, and rabbits. We hope to one day catch some sightings of otters which we 
            believe visit the farm occasionally!  
          </p>
          <div className='small_gap'/>
          <p> 
            Have a look through some of the photos and videos below to see the different animals we have around. If you want more information
            about the different species then go check out our <Link to="/wildlife" onClick={() => {window.scroll(0, 0);}}>wildlife pages</Link> which 
            give some background about the various animals we share the land with. 
          </p>
        </div> 
      </div>
        <VideoGal species='all' />
    </div>
    )
}

export default GalleryCameraTraps;