import {useState, useEffect} from 'react';
import Navbar from './Navbar';
import MobileMenu from './NavbarMob';

const Header = () => {
  
  const [enableMobileMenu, setEnableMenu] = useState(false);
  
  useEffect(() => {
    const closeMobileMenu = () => {
      if (enableMobileMenu) {
        setEnableMenu(false);
      }
    };
  
    // Add click event listener to the document
    document.addEventListener('click', closeMobileMenu);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', closeMobileMenu);
    };
  }, [enableMobileMenu]);


  return (
    <header>
      <div className="main-menu">
        <div className="nav-area">
          <a href="/" className="logo">
            <img src="/logo/BCF_Logo.webp" width={100} alt='' />
          </a>
          <Navbar />
        </div>
        <div className="main-menu-mobile-mode">
          <a href="/" className="logo-mob">
            <img src='/logo/BCF_Logo.webp' width={100} />
          </a>
          <div className="menu-button-container">
            <button className="menu-button" onClick={() => setEnableMenu(!enableMobileMenu)}>
              <span className="hamburger-icon">&#9776;</span> {/* Hamburger icon */}
            </button>
            <div className="mobile-menu-area">
              {enableMobileMenu && <MobileMenu />}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
