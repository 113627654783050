


const mapItems = [
  {
    id: "1",
    location: {
      title: "Alpacas",
      text: "",
      image: "https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/MapItems%2Falpaca.png?alt=media&token=c59d70a9-b364-423b-925d-6be574a982d7",
      latitude: 51.6483950263937,
      longitude: -1.169170720047003,
      path: '/farm_animals/alpacas'
    }
  },
  {
    id: "2",
    location: {
      title: "Pigs",
      text: "",
      image: "https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/MapItems%2Fpig.png?alt=media&token=a9b0a834-0d8f-42df-b16a-b123c391b4b9",
      latitude: 51.63703924859583,
      longitude: -1.171172879795871,
      path: '/farm_animals/pigs'
    }
  },
  {
    id: "3",
    location: {
      title: "Horses",
      text: "",
      image: "https://parspng.com/wp-content/uploads/2022/08/horsepng.parspng.com_.png",
      latitude: 51.64875828570047,
      longitude: -1.1726841974545874,
      path: '/farm_animals/horses'
    }
  },
    {
      id: "4",
      location: {
        title: "Sheep",
        text: "",
        image: "https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/MapItems%2Fsheep.png?alt=media&token=b679fb90-af46-40fe-9acd-d2d2ae4b227c",
        latitude: 51.640499032024046,
        longitude: -1.1698359078365963,
        path: '/farm_animals/sheep'
      }
  },
    {
      id: "5",
      location: {
        title: "The scrapes",
        text: "",
        image: "https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/MapItems%2Fredkite.png?alt=media&token=a2be5724-ebff-475a-a7cc-633e6c88db82",
        latitude: 51.64082269440097,
        longitude: -1.17498023286978,
        path: '/wildlife/birds'
      }
  },
    {
      id: "6",
      location: {
        title: "Goats",
        text: "",
        image: "https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/MapItems%2Fgoat.png?alt=media&token=7e5e1be1-f1b7-4034-af9a-b844ccc9802d",
        latitude: 51.646011435729726,
        longitude: -1.170714883345437,
        path: '/farm_animals/goats'
      }
  },
    {
      id: "7",
      location: {
        title: "Bees",
        text: "",
        image: "https://freepngimg.com/thumb/bee/3-2-bee-png-6.png",
        latitude: 51.63848417557897,
        longitude: -1.1713445411754935,
        path: '/farm_animals/bees'
      }
    },
    {
      id: "8",
      location: {
        title: "Above the farm",
        text: "",
        image: "https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/MapItems%2Ffarmbuilding.png?alt=media&token=7411af5b-04e4-49f6-b074-a6c651d0f8bb",
        latitude: 51.647741951164534, 
        longitude: -1.169753758708083,
        path: '/camera/farmpano'
      }
    }

];

export default mapItems;


/*

import "../App.css";
import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs } from "firebase/firestore";
import {db} from '../db/firebase';


const MapPoints = () => {

    const addMapPoints = async (e) => {
        e.preventDefault();

        try {
            const docRef = await addDoc(collection(db, "locations"), {
            });
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    }

    const fetchPost = async () => {

        await getDocs(collection(db, "locations"))
            .then((querySnapshot)=>{
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                MapPoints(newData);
                console.log(MapPoints, newData);
            })

    }

    useEffect(()=>{
        fetchPost();
    }, [])
}

export default MapPoints; */
