import 'smart-frame';
import CarouselCat from '../components/CarouselOther';
import CallHistory from '../components/CallHistory';
import PhotoGal from '../components/PhotoGal';
import { Link } from 'react-router-dom';
import Sidebar from '../components/HistorySidebar';
import SidebarMob from '../components/HistorySidebarMob';
import { landscapeDesktop, landscapeMobile } from '../db/public_images';
import DykesHillsVid from '../components/DykeHillsVid';

function History() {
    let jsonData = landscapeMobile
    if (window.screen.availWidth > 768) {
      jsonData = landscapeDesktop
    }
    return (
        <div>
        <CarouselCat imageList = {jsonData} legend= "History of the farm" />
        <Sidebar />
        <SidebarMob />
        <div className="frontpagetext" >
        <div className='frontpagetext-inner' >
            <div id='introduction'>
            <p> 
                The farm and the village have a long history stretching back thousands of years. There have been settlers here since 
                the Neolithic period (new stone age) - around 6000 years ago, continuously until the modern day. For a short period 
                Dorchester was even the capital of the Kingdom of Wessex, before Winchester took the title from us. 
            </p>
            </div>
            <CallHistory
            name="historytimeline" type='' width="100%" />
            <div className='medium_gap'/>
            <div id="thestoneage"><h1> The Stone Age</h1></div>
            <div className='small_gap' />
            <p> 
                An ancient sacred site once existed in the north of the village. Neolithic sacred sites dating from the same period 
                include famous places such as Stonehenge. Sadly we have nothing remaining of our sacred site as it was on the site of 
                one of the many gravel pits that now exist around the village. 
            </p>
            <div className='medium_gap'/>
            <div id="theironage"><h1> The Iron Age</h1></div>
            <div className='small_gap' />
            <div className='columnContainer' >
                <div className='columnonesmall'>
                <p>
                    During the Iron Age - around 3000 years ago - the southern part of the farm was inhabited. The Dyke Hills were created 
                    by Iron Age settlers to act as fortifications to protect them. Their settlement was protected on two sides by the river 
                    Thames, one side by the Thame. The fourth side was the Dyke Hills, the central section of which would likely have 
                    been flooded through from the Thames to the Thame, providing a fourth wall of water to protect the settlement. 
                </p>
                </div>
                <div className='columntwo'> 
                    <DykesHillsVid />
                    <i> Drone footage along the Dyke Hills with an explanation of their history.</i>
                </div>
            </div>
            <div className='small_gap' />
            <CallHistory name='Dykes1890' type='' width='100%' />
            <i>
                The Dyke Hills were built by the Iron Age settlers in Dorchester in order to protect their city which was situated to 
                the south of the Dykes and protected on all other sides by the rivers. 
            </i>
            <div className='small_gap' />
            <p>
                During the same period Castle Hill of Wittenham Clumps was used as a fort. The fort was a substantial protective 
                structure, and it is thought that the towns of Dorchester and Shillingford would have relayed messages up to Castle Hill 
                when they saw invaders on the horizon. 
            </p>
            <Link to='/camera/ironage' onClick={() => {window.scroll(0, 0);}}>
                <p>
                    Click here if you want to see a recreation of what the Iron Age town at the Dyke hills may have looked like!
                </p>
            </Link>
            <div className='medium_gap'/>
            <div id="theromans"><h1> The Romans</h1></div>
            <div className='small_gap' />
            <p> 
                Roman occupation of Britain began in 43 CE, and continued until the year 410 CE. During this time Dorchester was a hub 
                of activity. It is one of only two Roman walled towns in Oxfordshire - the other being Alchester. 
                Roman coins and pottery have been discovered across much of the village and surrounding countryside. 
                The main Roman walled town was in the middle of the village, covering roughly the site where the allotments are today,
                as well as extending towards the location of the current abbey - as shown on the map below. 
            </p>
            <Link to='/camera/romanera' onClick={() => {window.scroll(0, 0);}}>
                <p>
                    Click here if you want to see a recreation of what the Roman City in Dorchester may have looked like!
                </p>
            </Link>
            <div className='small_gap' />
            <CallHistory name='RomanDefences' type='' width='100%' /> 
            <i> 
                Map showing the location of the Roman walled town in Dorchester and indicating where we believe the gates for entry may 
                have been. 
            </i>
            <div className='small_gap' />
            <p> 
                Other archaeological evidence has been found outside the walled area where people would have also lived. Cropmarks have 
                been found in the farm land, between the house and the river. These have been dated to Saxon or Roman times. 
                The proposals for Roman times suggest that it 
                may have been ditches and buildings from farmland, a Roman river port allowing goods to be delivered, or potentially 
                even a Roman military complex.
            </p>
            <CallHistory name='BishopsCourtRectangle' type='' width='100%' />
            <i> 
                Schematic showing the layout of the cropmarks at Bishop's Court Rectangle. The purpose of the site is not fully known 
                but it is believed to be a Roman site. 
            </i>
            <div className='medium_gap'/>
            <div id="thesaxons"><h1> The Saxons</h1></div>
            <div className='small_gap' />
            <div className="columnContainer">
                    <div className="columnonesmall">
                        <div className='small_gap' />
                        <CallHistory name='birinus_cynegils' type='' width='100%' />
                        <i>
                            Stained glass window in Dorchester Abbey depicting Birinus preaching to King Cynegils and his people.
                        </i>
                    </div>
                    <div className="columntwo">
                        <p> 
                            During the Saxon period in Britain, dating from around 400 CE, the country was split into different Kingdoms 
                            which were often at odds with each other whilst battling for more land. Dorchester was situated near the 
                            borders of several of the kingdoms, most notably Wessex and Mercia. 
                        </p>
                        <p>
                            Wessex would eventually become the most important kingdom as 
                            it was a king of Wessex - Aethelstan - who united the four remaining kingdoms of Wessex, Mercia, Northumbria 
                            and East Anglia in 927 CE.
                        </p>
                        <p> 
                            By the 600s Dorchester was within the kingdom of Wessex. This period of history is incredibly important for 
                            Dorchester. In 634 CE, King Cynegils was King of Wessex, and Birinus,
                            a missionary sent by the Pope from Italy, was trying to convert him to Christianity. When King Oswald of Northumbria was 
                            visiting King Cynegils, in order to form an alliance, he helped convince Cynegils to convert. Birinus then christened 
                            Cynegils and his family in the river near to where they were situated. This would most likely have been the Thames or the Thame 
                            in Dorchester. 
                        </p>
                    </div>
                </div>
                <div className="columnContainer">
                    <div className="columnonelarge">
                        <div className="small_gap"></div>
                        <p> 
                            After the baptism, Cynegils' daughter, Cyneburg, was married to King Oswald, cementing their alliance. Birinus was 
                            granted the bishopric of Dorchester and permitted to build a cathedral there. This cathedral is believed to have 
                            stood on the site where our abbey is today. For several decades, until the bishopric was transferred to 
                            Winchester in 660 CE, Dorchester was in effect head of the Wessex church and acted as a capital city. 
                        </p>
                        <p> 
                            Following the transfer of Dorchester's bishopric to Winchester, several other bishops made Dorchester their home over the 
                            years, however it never again reached the grandeur of being a kingdom capital!
                        </p>
                    </div>
                    <div className="columntwo">
                        <div className="small_gap"></div>
                        <CallHistory name='birinus' type='' width='100%' />
                        <i>
                            Stained glass window in the St Birinus chapel in Dorchester Abbey.
                        </i>
                    </div>
                </div>
            <div className='medium_gap'/>
            <div id="medievalperiod"><h1> Medieval Period</h1></div>
            <div className='small_gap' />
            <p>
                In the 12th century, the Abbey was established as a home for an order of Augustinian canons, an order of monks. 
                During their time in the Abbey it was developed and rebuilt to the Abbey as we know it today. Whilst Dorchester no 
                longer had a bishop, the Bishop of Lincoln retained Bishop's Court to conduct their dealings from. 
            </p>
            <p>
                During the medieval period it was common for bishops to act as judges in cases of law. It may be that the name Bishop's 
                Court has come from the fact that the area where the house now stands, was an area that once hosted the 
                Bishop's Court. Bishops may have congregated here to lay down the law and order and sentence people to their deaths!  
            </p>            
            <p> 
                At the dissolution of the monasteries, undertaken by Henry VIII, the monks were forced to leave the Abbey and their 
                lands were sold off. Bishop's Court Farm was sold to the Dunch family of Little Wittenham in 1585. It remained in 
                their family for around 200 years until being sold to William Hallett in 1783. 
            </p>
            <div className='medium_gap'/>
            <div id="recenthistory"><h1>Recent History</h1></div>
            <div className='small_gap' />
            <p> 
                During the late 19th century the farm owner at the time started to flatten the Dyke hills to create more arable land. We 
                are fortunate that General Pitt-Rivers (who many will know for the museum in Oxford being named after him) found out 
                about the destruction of the Dykes and encouraged the government to implement protection of the monument. As a result 
                of his work the scheduled ancient monuments act was passed to protect historical monuments and archaeological sites 
                such as the Dykes. 
            </p>
            <div className='medium_gap' />
            <CallHistory name='Dykes1872' type='' width='100%'/> 
            <i>
                The Dyke Hills in 1872. The partial destruction of the hills is visible in the foreground where the farmer was flattening it.
            </i>
            <div className='small_gap' />
            <p>
                Throughout the majority of the 20th century the farm was held by the Bowditch family who bought it in 1928 and owned it 
                until the death of Miss Bowditch in 2016. The farm changed during this time, growing in size to 1000 acres in the 1990s, where 
                it had only been around 150 acres in 1928. The farm was used for growing crops, and also keeping cattle and horses.
                Horse jumping and racing events were held on the farm during the 1940s and 50s, we believe they would have used the footpaths 
                around the farm for their cross country circuits. 
            </p>
        </div>
        </div>
        <div id="historygallery"><h1> History Gallery </h1></div>
        <p>    
        </p>
        <PhotoGal link={'https://farm-website-backend-6924ec883f42.herokuapp.com/history?type=photo'} />
     </div>
    )
}

export default History;