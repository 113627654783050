import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  OverlayView,
  GroundOverlay
} from "@react-google-maps/api";
import '../App.css';
import mapItems from "./MapItems";
import { useNavigate } from "react-router-dom";


type CurrentLocation = {
  latitude: number;
  longitude: number;
}

type MapItemsNode = {
  id: string;
  location: {
    title: string;
    text: string;
    image: string;
    latitude: number;
    longitude: number;
    path: string;
  };
};

export default function HistoryMap () {

let navigate = useNavigate(); 
const routeChange = (path: string) => {
  navigate(path);
  window.scrollTo(0, 0);
};


  const position = ({
    lat: 51.63503924859583 - 0.0055,
    lng: -1.173172879795871 + 0.039
  })

  const urlRoman = "/maps/romancitycompressed.webp"

  const imageBoundsRoman = {
    north: 51.64556249558674,
    south: 51.641126813543764,
    east:  -1.163699353086759,
    west: -1.1687,
  };

  const url1840 = '/maps/dorchester1840compressed.webp'

  const fudge = 0.0017
  const toffee = 0.0023
  const imageBounds1840 = {
    north: 51.664613 - toffee - (toffee / 4),
    south: 51.629208 + toffee / 13,
    east:  -1.132437 - fudge * 3,
    west: -1.194877 + fudge,
  };

  const url1940 = '/maps/OS1920compressed.webp'

  const imageBounds1940 = {
    north: 51.67009593053994,
    south: 51.61634301325687,
    east:  -1.1366439794875445,
    west: -1.196248,
  };

  const url1960 = '/maps/OSmap1960compressed.webp'

  const imageBounds1960 = {
    north: 51.655740,
    south: 51.628991,
    east:  -1.141281,
    west: -1.202186,
  };

  const urlfuture = '/maps/farmfuturemap.png'

  const imageBoundsfuture = {
    north:  51.65168710105617,
    south: 51.63318269133752,
    east:  -1.1552536137660744,
    west: -1.1901210961024517,
  };
  
  const mapRef = React.useRef<any>(null);
  const { isLoaded } = useJsApiLoader({
  id: "google-map-script",
  googleMapsApiKey: "AIzaSyDNqnMDVPMJ8cMCYAqiYZjj3tJqWiNXsAE"
  });


  const [currentImage, setCurrentImage] = useState("1960"); // Initial image selection
  const [currentPosition, setCurrentPosition] = React.useState<CurrentLocation | undefined | null>(null);
  const [opacity, setOpacity] = React.useState<number>(0.7); // Initial opacity value
  const [zoom, setZoom] = React.useState<number>(13.6); // Initial opacity value
  const [activeMap, setActiveMap] = useState("1960");
  const [center, setCenter] = React.useState({lat: 51.645107, lng: -1.166157}); // Initial opacity value
  const [selectedMapItems, setselectedMapItems] = React.useState<
  MapItemsNode | undefined | null
  >(null);
  const [showMarkers, setShowMarkers] = useState(true); // Initial value is true to show markers
  const toggleMarkers = () => {
    setShowMarkers(!showMarkers); // Toggle the value (true to false, or false to true)
  };
  const [infoWindowOpen, setInfoWindowOpen] = React.useState<
     InfoWindow | undefined | null
     >(null);

  const getCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getCurrentPosition();
  }, []);

  const handleOpacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOpacity = parseFloat(event.target.value);
  setOpacity(newOpacity);
  };
    
  const switchTo1960 = () => {
    setCurrentImage("1960");
    setZoom(14);
    setActiveMap("1960");
    setCenter({lat:51.64269396001814, lng:-1.1707830493288602})
  };

  const switchTo1940 = () => {
    setCurrentImage("1940");
    setZoom(13.6);
    setActiveMap("1940");
    setCenter({lat: 51.645107, lng: -1.166157})
  };

  const switchTo1840 = () => {
    setCurrentImage("1840");
    setZoom(13.6);
    setActiveMap("1840");
    setCenter({lat: 51.645107, lng: -1.166157})
  };

  const switchToFuture = () => {
    setCurrentImage("future");
    setZoom(13.6);
    setActiveMap("future");
    setCenter({lat: 51.645107, lng: -1.166157})
  };

  const switchToRoman = () => {
    setCurrentImage("Roman");
    setZoom(16.5);
    setActiveMap("Roman");
    setCenter({lat:51.64304234419824,lng:-1.1669093775003638})
  };


  const onLoad = React.useCallback(
    (mapInstance: { fitBounds: (arg0: google.maps.LatLngBounds) => void; }) => {
    const bounds = new google.maps.LatLngBounds();
    mapItems.forEach((mapItems) => {
        bounds.extend(
        new google.maps.LatLng(
          mapItems.location.latitude,
          mapItems.location.longitude
        )
        );
    });
    mapRef.current = mapInstance;
    mapInstance.fitBounds(bounds);
    },
    [mapItems]
    );

  const onClickMarker = (mapItemsId: string) => {
    setselectedMapItems(mapItems.find((mapItems) => mapItems.id === mapItemsId));
    };

  const color = {
    'google-blue 100': `#4285F4`,
    'white 100': `rgb(255,255,255)`,
  }


  const blueDot = {
    fillColor: color['google-blue 100'],
    fillOpacity: 1,
    path: 0.0,
    //path: google.maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeColor: color['white 100'],
    strokeWeight: 2,
  };

  return (
    <div className="container">
    {isLoaded && (
      <>
        <div className='historybuttoncontainer'>
          <button 
            className={`historyButtons ${activeMap === "1960" ? "historyButton-active" : ""}`} 
            onClick={switchTo1960}>
              Switch to 1960 Map
          </button>
          <button 
            className={`historyButtons ${activeMap === "1940" ? "historyButton-active" : ""}`} 
            onClick={switchTo1940}>
              Switch to 1940 Map
          </button>
          <button 
            className={`historyButtons ${activeMap === "1840" ? "historyButton-active" : ""}`} 
            onClick={switchTo1840}>
              Switch to 1840 Map
          </button>
          <button 
            className={`historyButtons ${activeMap === "future" ? "historyButton-active" : ""}`} 
            onClick={switchToFuture}>
              Switch to Future Map
          </button>
          <button 
            className={`historyButtons ${activeMap === "Roman" ? "historyButton-active" : ""}`} 
            onClick={switchToRoman}>
              Switch to Roman Map
          </button>
          <button className='historyButtons' onClick={toggleMarkers}>
            {showMarkers ? "Hide Markers" : "Show Markers"}
          </button>
        </div>
        <div className='mapcontainer'>
          <GoogleMap
            mapContainerClassName="history_map1"
            onLoad={onLoad}
            center={center}    
            zoom={zoom}
            options={{
                mapTypeId: google.maps.MapTypeId.HYBRID,
                streetViewControl: true,
                mapTypeControl: true,
                styles: [
                  {
                    "featureType": "poi",
                    "elementType": "labels.icon",
                    "stylers": [
                      {
                        "visibility": "off"
                      }
                    ]
                  },
                  {
                    "featureType": "poi.place_of_worship",
                    "elementType": "labels.icon",
                    "stylers": [
                      {
                        "visibility": "on"
                      }
                    ]
                  }
                ]
            }}
          >

            {showMarkers && currentPosition && (
              <Marker
                position={{
                  lat: currentPosition.latitude,
                  lng: currentPosition.longitude,
                }}
                icon={blueDot}
                onClick={() => setInfoWindowOpen}
                
              />
            )}
            {/* {showMarkers && currentPosition && (
              <InfoWindow
              position={{
                lat: currentPosition.latitude,
                lng: currentPosition.longitude,
              }}
              onCloseClick={() => setInfoWindowOpen(null)} // Close the InfoWindow when clicked on the close button
              >
              <div><p>You are here!</p></div>
            </InfoWindow>
            )} */}

            {position && (
              <GroundOverlay
              key={currentImage}
              url={
                currentImage === "1960" ? url1960 
                : currentImage === "1840" ? url1840 
                : currentImage === "1940" ? url1940 
                : currentImage === "future" ? urlfuture 
                : urlRoman
              }
              bounds={
                currentImage === "1960" ? imageBounds1960 
                : currentImage === "1840" ? imageBounds1840 
                : currentImage === "1940" ? imageBounds1940 
                : currentImage === "future" ? imageBoundsfuture
                : imageBoundsRoman
              }
              opacity={opacity}
              visible={false}
            />
         
            )}

              {showMarkers && mapItems.map((mapItems) => (
              <Marker
                key={mapItems.id}
                icon = {{url: mapItems.location.image, scaledSize: new google.maps.Size(50, 50)}}
                onClick={() => onClickMarker(mapItems.id)}
                position={{
                  lat: mapItems.location.latitude,
                  lng: mapItems.location.longitude
                }}
              />
            ))}
          {selectedMapItems && (
              <InfoWindow
                position={{
                  lat: selectedMapItems.location.latitude,
                  lng: selectedMapItems.location.longitude
                }}
                onCloseClick={() => setselectedMapItems(null)}
              >
                <>
                  <h1>{selectedMapItems.location.title}{" "}</h1>
                  <p >{selectedMapItems.location.text}{" "}</p>
                  <div className="button-container">
                  <button type="button" onClick={() => routeChange(selectedMapItems.location.path)}>
                      Click me to learn more!
                    </button>
                  </div>
                </>

              </InfoWindow>
              
            )}

          </GoogleMap>

          <div className="frontpagetext">
            <h4>Transparency:  </h4>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={opacity}
              onChange={handleOpacityChange}
            />
          </div>
        </div>
        <div className="white-space"></div>
        </>
      )}
      </div>
    )
}
