import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../App.css';

function CarouselCat({ imageList, legend }) {
    return (
        <div className="carouselfull" >
        <Carousel autoPlay showThumbs={false} interval={4000} infiniteLoop>
            {imageList.map((item) => (
                <div key={item.id} className="custom-carousel">
                    <a>
                        <img src={item.src} alt={item.alt} />
                    </a>
                    <div className="legendStyle">
                        <h1>{legend}</h1>
                    </div>
                </div>
            ))}
        </Carousel>
        </div>
    );
}

export default CarouselCat;
