import React from 'react';
import '../App.css'
import { Link } from 'react-scroll';
import { useEffect, useState } from 'react';

const Sidebar: React.FC = () => {

    const [isFixed, setIsFixed] = useState(false);
    const [activeSection, setActiveSection] = useState<string | null>(null);

    useEffect(() => {
        const handleScroll = () => {
          const headerHeight = 100; // Adjust this value to match your header's height
          const carouselHeight = 300; // Adjust this value to match your carousel's height
          const scrollTop = window.scrollY;
    
          // Check if the user has scrolled past the header and carousel
          if (scrollTop > headerHeight + carouselHeight) {
            setIsFixed(true);
          } else {
            setIsFixed(false);
          }
    
          // Determine the active section based on scroll position
          const sections = ['thestoneage', 'theironage', 'theromans', 'thesaxons', 'medievalperiod', 'recenthistory']; // Add your section IDs here
          for (let i = sections.length - 1; i >= 0; i--) {
            const section = sections[i];
            const sectionElement = document.getElementById(section);
            if (sectionElement && sectionElement.offsetTop <= scrollTop) {
              setActiveSection(section);
              break;
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const sidebarStyle: React.CSSProperties = {
        position: isFixed ? 'fixed' : 'absolute',
        top: isFixed ? '0' : 'auto',
        left: '0',
        width: '200px',
        backgroundColor: '#003a70',
        color: '#c2e5a6',
        padding: '20px',
        zIndex: 1,
        margin: '1rem',
      };

  return (
    <div className='sidebar' >
    <div style={sidebarStyle}>
    <h3> Jump to:</h3>
    <div className='small_gap' />
      <ul>
         <li>
          <Link 
            to="introduction" 
            smooth={true} 
            duration={500}
            spy={true}
            className={activeSection === 'introduction' ? 'active' : ''}
          >
          <h5>Introduction</h5>
          </Link>
        </li>
        <li>
          <Link 
            to="thestoneage" 
            smooth={true} 
            duration={500}
            spy={true}
            className={activeSection === 'thestoneage' ? 'active' : ''}
          >
          <h5>The Stone Age</h5>
          </Link>
        </li>
        <li>
          <Link 
            to="theironage" 
            smooth={true} 
            duration={500}
            spy={true}
            className={activeSection === 'theironage' ? 'active' : ''}
          >
          <h5>The Iron Age</h5>
          </Link>
        </li>
        <li>
          <Link 
            to="theromans" 
            smooth={true} 
            duration={500}
            spy={true}
            className={activeSection === 'theromans' ? 'active' : ''}
          >
          <h5>The Romans</h5>
          </Link>
        </li>
        <li>
          <Link 
            to="thesaxons" 
            smooth={true} 
            duration={500}
            spy={true}
            className={activeSection === 'thesaxons' ? 'active' : ''}
          >
          <h5>The Saxons</h5>
          </Link>
        </li>
        <li>
          <Link 
          to="medievalperiod" 
          smooth={true} 
          duration={500}
          spy={true}
          className={activeSection === 'medievalperiod' ? 'active' : ''}
          >
          <h5>Medieval Period</h5>
          </Link>
        </li>
        <li>
          <Link 
            to="recenthistory" 
            smooth={true} 
            duration={500}
            spy={true}
            className={activeSection === 'recenthistory' ? 'active' : ''}
          >
          <h5>Recent History</h5>
          </Link>
        </li>
        <li>
          <Link 
            to="historygallery" 
            smooth={true} 
            duration={500}
            spy={true}
            className={activeSection === 'historygallery' ? 'active' : ''}
          >
          <h5>Gallery</h5>
          </Link>
        </li>
      </ul>
    </div>
    </div>
  );
};

export default Sidebar;
