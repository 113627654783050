
import React, { useEffect } from 'react';
import 'aframe';
import 'aframe-particle-system-component';
import {Entity, Scene} from 'aframe-react';

function VideoPlayer({videoPath}) {

  return (
    <a-scene>
      <a-assets>
      <video id="video" preload="auto"
               src= {videoPath}
               autoPlay
               crossOrigin="anonymous"
               ></video>
      </a-assets>
      <a-videosphere
        rotation="0 -90 0"
        src="#video"
        visible="true"
        play-on-click
        >
      </a-videosphere>
      <a-camera>
      </a-camera>
    </a-scene>
  );
};


export default VideoPlayer;



