import ImageScene from "../../components/360PhotoViewer";
import { useNavigate } from "react-router-dom";

function ScrapesPano() {
    const imagePath = 'https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/360%20Footage%2Fscrape360.JPG?alt=media&token=5b39e87c-cecf-43b4-927f-7c8e8a4ae04e'; // Replace with your image path
    
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `/camera/modernday`; 
      navigate(path);
      window.location.reload();
    }

return (
    <div>
    <div style={{ position: 'relative' }}>
        <ImageScene imagePath={imagePath} />
        <button onClick={routeChange}
            style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                background: 'transparent',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
                color: 'white', 
            }}
        >
            X
        </button>
    </div>
</div>
)
}

export default ScrapesPano;