import ReactDOM from 'react-dom';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './App';
import FarmAnimals from './pages/FarmAnimals';
import Wildlife from './pages/Wildlife';
import Badgers from './pages/Wildlife/Badgers';
import Birdlife from './pages/Wildlife/Birdlife';
import Deer from './pages/Wildlife/Deer';
import Foxes from './pages/Wildlife/Foxes';
import Rabbits from './pages/Wildlife/Rabbits';
import OtherSpecies from './pages/Wildlife/OtherSpecies';
import Component from './pages/Gallery';
import GalleryCameraTraps from './pages/GalleryCameraTraps';
import CameraComponent from './pages/Camera';
import Image from './pages/CameraPic';
import VideoViewers from './pages/VideoViewers';
import VideoIronAges from './pages/VideoIronAges';
import VideoModernDay from './pages/VideoModernDay';
import VideoRomanEra from './pages/VideoRomanEra';
import RomanPhoto from './pages/CameraLinks/RomanPhoto';
import RomanVideo from './pages/CameraLinks/RomanVideo';
import IronAgeVideo from './pages/CameraLinks/IronAgeVideo';
import IronAgePhoto from './pages/CameraLinks/IronAgePhoto';
import SnugPhoto from './pages/CameraLinks/SnugPhoto';
import LakeFieldPano from './pages/CameraLinks/LakeFieldPano';
import LockPano from './pages/CameraLinks/LockPano';
import ScrapesPano from './pages/CameraLinks/ScrapesPano';
import FarmPano from './pages/CameraLinks/FarmPano';
import FarmhousePano from './pages/CameraLinks/FarmhousePano';
import AllotmentPano from './pages/CameraLinks/AllotmentPano';
import AboveFarmPano from './pages/CameraLinks/AboveFarmPano';
import Pigs from './pages/FarmAnimals/Pigs';
import Alpacas from './pages/FarmAnimals/Alpacas';
import Goats from './pages/FarmAnimals/Goats';
import Sheep from './pages/FarmAnimals/Sheep';
import Bees from './pages/FarmAnimals/Bees';
import Horses from './pages/FarmAnimals/Horses';
import BirdsAdd from './pages/BirdsAdd';
import BirdsView from './pages/BirdsView';
import BirdGallery from './pages/BirdGallery';
import ThankYouPage from './pages/ThankYouPage';
import History from './pages/History';
import HistoryMapDisplay from './pages/HistoryMapDisplay';
import HistoryMapNew from './pages/HistoryMapNew';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="farm_animals" element={<FarmAnimals />} />
          <Route path="wildlife" element={<Wildlife />} />
          <Route path="wildlife/badgers" element={<Badgers />} />
          <Route path="wildlife/birds" element={<Birdlife />} />
          <Route path="wildlife/deer" element={<Deer />} />
          <Route path="wildlife/foxes" element={<Foxes />} />
          <Route path="wildlife/rabbits" element={<Rabbits />} />
          <Route path="wildlife/otherspecies" element={<OtherSpecies />} />
          <Route path="gallery" element={<Component />} />
          <Route path="gallery/cameratraps" element={<GalleryCameraTraps />} />
          <Route path="camera" element={<CameraComponent />} />
          <Route path="camera/camerapics" element={<Image />} />
          <Route path="camera/videoviewers" element={<VideoViewers />} />
          <Route path="camera/ironage" element={<VideoIronAges />} />
          <Route path="camera/romanera" element={<VideoRomanEra />} />  
          <Route path="camera/modernday" element={<VideoModernDay />} />
          <Route path="farm_animals/pigs" element={<Pigs />} />    
          <Route path="farm_animals/sheep" element={<Sheep />} />    
          <Route path="farm_animals/alpacas" element={<Alpacas />} />    
          <Route path="farm_animals/horses" element={<Horses />} />    
          <Route path="farm_animals/goats" element={<Goats />} />    
          <Route path="farm_animals/bees" element={<Bees />} />    
          <Route path="birds/sightings" element={<BirdsAdd />} />    
          <Route path="birds/view" element={<BirdsView />} />   
          <Route path="birds/gallery" element={<BirdGallery />} />
          <Route path="birds/thankyou" element={<ThankYouPage />} />
          <Route path="history" element={<History />} />
          <Route path="history/maps" element={<HistoryMapDisplay />} />
          <Route path="history/mapnew" element={<HistoryMapNew />} />
        </Route>
        <Route path="/"> 
        <Route path="camera/ironagevideo" element={<IronAgeVideo />} />     
          <Route path="camera/ironagephoto" element={<IronAgePhoto />} />     
          <Route path="camera/snugphoto" element={<SnugPhoto />} />     
          <Route path="camera/lakefieldpano" element={<LakeFieldPano />} />
          <Route path="camera/lockpano" element={<LockPano />} />
          <Route path="camera/scrapespano" element={<ScrapesPano />} />
          <Route path="camera/farmpano" element={<FarmPano />} />
          <Route path="camera/farmhousepano" element={<FarmhousePano />} />
          <Route path="camera/allotmentpano" element={<AllotmentPano />} />
          <Route path="camera/abovefarmpano" element={<AboveFarmPano />} />
          <Route path="camera/romanvideo" element={<RomanVideo />} />  
          <Route path="camera/romanphoto" element={<RomanPhoto />} />  
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));