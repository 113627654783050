import VideoPlayer from "../../components/360VideoPlayer";
import { useNavigate } from "react-router-dom";

function IronAgeVideo() {
    const videoPath =
        'https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/360%20Footage%2FIronAgeCityVid_injected.mp4?alt=media&token=e5b3412f-27fc-4e9d-b633-81911f892e69';

let navigate = useNavigate(); 
const routeChange = () =>{ 
  let path = `/camera/ironage`; 
  navigate(path);
  window.location.reload();
}
return (
<div>
<div style={{ position: 'relative' }}>
    <VideoPlayer videoPath={videoPath} />
    <button onClick={routeChange}
        style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            background: 'transparent',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            color: 'white', 
        }}
    >
        X
    </button>
</div>  
</div>  
)
}

export default IronAgeVideo;


