import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CarouselCat from '../../components/CarouselOther';
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import { beesDesktop, beesMobile } from "../../db/public_images";


function Bees() {
    let jsonData = beesMobile
    if (window.screen.availWidth > 768) {
      jsonData = beesDesktop
    }
    return (
        <div>
        <CarouselCat imageList={jsonData} legend= "Want to learn about bees?" />  
        <div className= "frontpagetext" >
        <div className='frontpagetext-inner' >
            <p> 
                We have 27 bee colonies on the farm, from which we get around 2000 jars of honey each year. These bees feed off nectar
                from our wildflower meadow and from other plants around the farm. They store the nectar in their hives, in little hexagonal 
                cells in the honeycomb, which gradually becomes honey as it dehydrates. We are then able to collect the honey, leaving enough 
                for the bees to keep them healthy through winter! 
            </p>
            <div className="small_gap" />
            <p> 
                There are more than 250 species of bee in the UK, but only one of these is domesticated, the European honey bee. 
                They are also the most common domesticated bee worldwide. Their natural habitat closely resembles the nests we create 
                for them. Many other bees do not live in colonies, or live in more specific habitats that are harder to recreate so they 
                cannot be domesticated in the same way. 
            </p>
            <div className="small_gap" />
            <h1> 
                Wildflower Meadow
            </h1>
            <div className="small_gap" />
            <p>
                The bees live right next to our meadow where we have planted a mix of British wildflowers. This improves the biodiversity of 
                the area and gives the bees a great selection of plants to collect nectar from, giving their honey more flavours. Our wildflower 
                meadow is funded by a government grant which promotes habitat creation and restoration. The aim is that by providing more 
                diverse habitats across the farm, not just crops and grazing areas, more insects and other wildlife will use the land. 
            </p>
            <div className="medium_gap" />
            <h1> 
                Want to learn more?
            </h1>
            <div className="small_gap" />
            <p> 
                To continue learning about our different farm animals please continue with the links below:
            </p>
        </div>
        </div>
        <div className="trapLinks">
        <div className="imageContainer">
            <Link to="/farm_animals/sheep" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='sheep_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Sheep</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/goats" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='goat_pygmy_baby_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Goats</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/pigs" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='pig_bob_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Pigs</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/alpacas" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='alpaca_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Alpacas</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/horses" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='horse_sunset_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Horses</h4>
            </div>
            </Link>
        </div>
        </div>
    </div>
    );
}

export default Bees;