import React, { useState, useEffect } from 'react';
import Autocomplete from '../components/Autocomplete';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import 'leaflet/dist/leaflet.css';
import '../App.css';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import CarouselCat from '../components/CarouselOther';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { birdsDesktop, birdsMobile } from '../db/public_images';


interface FormField {
  species: string;
  date: Date;
  breeding: string;
  photo: File | null;
  countData: string;
  comments: string;
  location: google.maps.LatLngLiteral | null;
}

function BirdsAdd() {

  const [formFields, setFormFields] = useState<FormField[]>([
    { species: '', date: new Date, breeding: '', photo: null, countData: '', comments: '', location: null },
  ]);

  const [dateOfSighting, setDateOfSighting] = useState<Date | null>(null);


  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const newData = [...formFields];
    newData[index][name] = value;

    // If the input field is the "Date of Sighting" field, update the state variable for date
    if (name === 'dateOfSighting') {
      const date = new Date(value);
      newData[index][name] = date;
      setDateOfSighting(date);
    }

    setFormFields(newData);
  };


  const handleSpeciesChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const newData = [...formFields];
    newData[index].species = value;
    setFormFields(newData);
  };

  const handleBreedingChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const newData = [...formFields];
    newData[index].breeding = value;
    setFormFields(newData);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, files } = event.target;
    const newData = [...formFields];
    newData[index].photo = files?.[0] || null;
    setFormFields(newData);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent | null, index: number) => {
    if (event) {
      const { latLng } = event;
      if (latLng) { // Add null check for latLng
        const newData = [...formFields];
        newData[index].location = {
          lat: latLng.lat(),
          lng: latLng.lng(),
        };
        setFormFields(newData);
      }
    }
  };

  const navigate = useNavigate();
  
    const submit = async (e: React.FormEvent) => {
      e.preventDefault();
        // Convert the dateOfSighting to string before sending to the API
        const formData = await Promise.all(
          formFields.map(async (field) => {
            // Check if the date is a valid date string and convert it to a Date object
            const date = field.date ? new Date(field.date) : null;
      
            return {
              ...field,
              date: date instanceof Date ? date.toISOString() : field.date,
              photo: field.photo ? await convertFileToBase64(field.photo) : null,
            };
          })
        );
      
      console.log(JSON.stringify(formData, null, 2));

      try {
        const response = await axios.post('https://farm-website-backend-6924ec883f42.herokuapp.com/birdrec/add', formData);
        //console.log('Sighting submitted successfully:', response.data);
        console.log(response)
        if (response.status == 200) {
          navigate('/birds/thankyou');
        }
        
        // Optionally, you can show a success message to the user
      } catch (error) {
        console.log('Error submitting sighting:', error);
        // Optionally, you can show an error message to the user
        window.scrollTo(0, 0)
        alert('Error submitting sighting, please try again');
        }      
      
    };

    const convertFileToBase64 = (file: File): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
  
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            resolve(null);
          }
        };
  
        reader.onerror = () => {
          reject(new Error('Error reading file'));
        };
  
        reader.readAsDataURL(file);
      });
    };

  const addFields = () => {
    const object: FormField = {
      species: '',
      date: new Date,
      breeding: '',
      photo: null,
      countData: '',
      comments: '',
      location: null,
    };

    setFormFields([...formFields, object]);
  };

  const removeFields = (index: number) => {
    const data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

    const handleAutocompleteClick = (event: React.MouseEvent<HTMLLIElement>, index: number) => {
      const { innerText } = event.target as HTMLLIElement;
      const newData = [...formFields];
      newData[index].species = innerText;
      setFormFields(newData);
    };

  const handleAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    // Handle autocomplete keydown event
  };


  // Fetch species suggestions from API
  const [speciesSuggestions, setSpeciesSuggestions] = useState<string[]>([]);

  useEffect(() => {
    fetchSpeciesSuggestions();
  }, []);

  const fetchSpeciesSuggestions = async () => {
    try {
      const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/bird/displayName');
      const data = await response.json();
      setSpeciesSuggestions(data);
    } catch (error) {
      console.log('Error fetching species suggestions:', error);
    }
  };

  // Fetch breeding behavior suggestions from API
  const [breedingSuggestions, setBreedingSuggestions] = useState<string[]>([]);

  useEffect(() => {
    fetchBreedingSuggestions();
  }, []);

  const fetchBreedingSuggestions = async () => {
    try {
      const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/birdBreeding/behaviour');
      const data = await response.json();
      setBreedingSuggestions(data);
    } catch (error) {
      console.log('Error fetching breeding behavior suggestions:', error);
    }
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDNqnMDVPMJ8cMCYAqiYZjj3tJqWiNXsAE',
  });

  let jsonData = birdsMobile
  if (window.screen.availWidth > 768) {
    jsonData = birdsDesktop
  }

  return (
    <div>
      <CarouselCat imageList={jsonData} legend='Submit a bird sighting' />
    <div className="form-box">
          <form className="form" onSubmit={submit}> {/* Add a class for the form */}
        {formFields.map((form, index) => (
      <div key={index}>
        <div className="form-fields-container">
            <label htmlFor="species"><p>Species:</p></label>
            <Autocomplete
              suggestions={speciesSuggestions}
              onClick={(event) => handleAutocompleteClick(event, index)}
              onKeyDown={(event) => handleAutocompleteKeyDown(event, index)}
              value={form.species}
              onChange={(event) => handleSpeciesChange(event, index)}
            />

            <label htmlFor="dateOfSighting">
              <p>Date of Sighting:</p>
            </label>
            <DatePicker
              selected={dateOfSighting}
              onChange={(date: React.SetStateAction<Date | null>) => setDateOfSighting(date)}
              name="dateOfSighting"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Select a date"
              autoComplete="off"
            />

            <label htmlFor="breeding">
              <p>Breeding Behaviour:</p>
            </label>
            <Autocomplete
              suggestions={breedingSuggestions}
              onClick={(event) => handleAutocompleteClick(event, index)}
              onKeyDown={(event) => handleAutocompleteKeyDown(event, index)}
              value={form.breeding}
              onChange={(event) => handleBreedingChange(event, index)}
              isDropdown={true} // Set isDropdown prop to true
            />
            <label htmlFor="photo">
              <p> </p>
              <p>Photo:</p>
            </label>
            <input
              type="file"
              name="photo"
              accept="image/png, image/jpeg"
              onChange={(event) => handleFileChange(event, index)}
            />
            <label htmlFor="count">
              <p>How many individuals?</p>
            </label>
            <input
              type="text"
              name="countData"
              value={form.countData}
              onChange={(event) => handleFormChange(event, index)}
            />
            <label htmlFor="comments">
              <p>Further comments:</p>
            </label>
            <input
              type="text"
              name="comments"
              value={form.comments}
              onChange={(event) => handleFormChange(event, index)}
            />
            <label htmlFor="position" className="form label-text">
              <p>Please select the rough area on the map where you spotted these individuals:</p>
            </label>
            <div style={{ height: '400px' }}>
              {isLoaded ? (
                <GoogleMap
                  center={{ lat: 51.64300944250453, lng: -1.172009060882794 }}
                  zoom={14}
                  mapContainerStyle={{ height: '100%', width: '100%' }}
                  onClick={(event) => handleMapClick(event, index)}
                  options={{
                      streetViewControl: false,
                      mapTypeId: google.maps.MapTypeId.SATELLITE,
                      mapTypeControl: false,
                  }}
                >
                  {form.location && <Marker position={form.location} />}
                </GoogleMap>
              ) : null}
            </div>
            <div className="button-container">
              <button type="button" onClick={() => removeFields(index)}>
                Remove Form
              </button>
            </div>
          </div>
        </div>
        ))}
        <div className="button-container">
            <button type="button" onClick={addFields}>
              Add Form
            </button>
            <button type="submit">Submit</button>
        </div>
      </form>
    </div>
    </div>
  );
}

export default BirdsAdd;