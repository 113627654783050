import { Link } from "react-router-dom";
import CarouselCat from '../components/CarouselOther';
import { landscapeDesktop, landscapeMobile } from '../db/public_images';

function VideoRomanEra () {
    let jsonData = landscapeMobile
    if (window.screen.availWidth > 768) {
      jsonData = landscapeDesktop
    }
    return (
        <div>
        <CarouselCat imageList = {jsonData}  legend= "Roman Era" />
        <div className="frontpagetext"> 
        <div className='frontpagetext-inner' >
            <p> 
                Dorchester is one of the largest Roman settlements in the country, and one of only two walled Roman towns in Oxfordshire. 
                The main walled settlement was situated where the allotments are today in the center of the village. Other people would 
                have lived outside the walled area, expanding the size of the settlement. If you live in the village you may remember that 
                archaeologists have excavated large areas around the allotments in recent decades. These excavations were focused on 
                findings from Roman Era Britain. 
            </p>
            <div className="small_gap" />
            <p> 
                We have recreated what the walled town of Roman Dorchester - or as it was then known, Dorocina - may have looked like. 
                Please have an explore of our Roman town!
            </p>
            <div className="small_gap" />
            <div>
                <div className="imageContainer">
                    <Link to="/camera/romanphoto">
                    <div className="linkbanner">
                        <h4>Roman Era Photo - minecraft recreation</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/camera/romanvideo">
                    <div className="linkbanner">
                        <h4>Roman Era Video - minecraft recreation</h4>
                    </div>
                    </Link>
                </div>
            </div>
        </div>
        </div>
        </div>
    )
}

export default VideoRomanEra; 