import ImageScene from "../../components/360PhotoViewer";
import { useNavigate } from "react-router-dom";

function FarmPano() {
    const imagePath = 'https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/360%20Footage%2Ffarmabove.JPG?alt=media&token=428b0430-93a6-4171-97f8-155728a585fc'; // Replace with your image path

let navigate = useNavigate(); 
const routeChange = () =>{ 
  let path = `/camera/modernday`; 
  navigate(path);
  window.location.reload();
}

return (
<div>
<div style={{ position: 'relative' }}>
    <ImageScene imagePath={imagePath} />
    <button onClick={routeChange}
        style={{
            position: 'absolute',
            right: '20px',
            background: 'transparent',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            color: 'white', 
        }}
    >
        X
    </button>
</div>
</div>
)
}

export default FarmPano;