import '../../App.css'
import { Link } from "react-router-dom";
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import CarouselCat from "../../components/CarouselOther";
import VideoGal from "../../components/VideoGal";
import { badgerDesktop, badgerMobile } from "../../db/public_images";

function Badgers() {
    let jsonData = badgerMobile
    if (window.screen.availWidth > 768) {
      jsonData = badgerDesktop
    }
    return (
        <div>
            <CarouselCat imageList = {jsonData}  legend='Want to learn about badgers?' />
            <div className= "frontpagetext" >
             <div className='frontpagetext-inner' >
                <p>
                    Badgers are the largest mammal in the UK (apart from humans!). They are a protected species as many people 
                    have hunted them over time which resulted in their populations being decimated. However since their protection
                    in 1992, their populations have started to improve. We are lucky enough to host several badger setts, each of 
                    which contain families of normally up to six badgers. 
                </p>
                <div className='medium_gap' />
                <h1>
                    Badger Diet
                </h1>
                <div className="small_gap" />
                <p>
                    For such a large animal their diet is surprising - around 80% of their diet is made up of earthworms, which they
                    can eat hundreds of each night. They are omnivorous so the rest of their diet can be made up of anything from fruit,
                    to insects, to bird eggs, to small mammals or amphibians. In fact, badgers are the primary natural predator of 
                    hedgehogs in the UK. 
                </p>
                <div className='medium_gap' />
                <h1> 
                    Badger Setts
                </h1>
                <div className="small_gap" />
                <div className="columnContainer">
                    <div className="columnonelarge">
                        <p> 
                            Badger setts are extensive networks of burrows and chambers which badgers dig out using their strong claws. They 
                            can span hundreds of square metres! Most badger clans have one main sett where most of their activity and breeding 
                            takes place. They also commonly have one or more secondary setts, which are used less often.
                            Setts can have several different entrances, including some less used ones which may be further from the main sett. 
                            As badgers are noctural, during the day they tend to stay inside their setts sleeping. Only once it reaches dusk do 
                            they tend to emerge for hunting and grooming with their families. Their most active months are during the summer 
                            when the weather is warm and there is lots of prey around to hunt. 
                        </p>  
                    </div>   
                    <div className="columntwo">
                        <CallSpeciesPhoto name='badger_1' species='' width='100%' />
                    </div>
                </div> 
                <p>
                    Badgers are very territorial animals, and their territories rarely overlap with any other badger clans. They mark
                    out their territories through scent marking to ensure other families know where they live and hunt. Whilst they do 
                    not like to share space with other badgers, they have been known to allow rabbits and even foxes to share their setts. 
                    In fact, from some of our camera trap data we believe that one of the farm badger setts is used by badgers, foxes 
                    and rabbits. 
                </p>
                <div className='medium_gap' />
                <h1> 
                    Badger Culling
                </h1>
                <div className="small_gap" />
                <p> 
                    Badgers have been protected since 1992, meaning it is illegal to kill or injure a badger, and we are encouraged to 
                    not disturb their setts. However farmers are still permitted with a license to cull badgers on their land due to 
                    misinformation about the spread of cattle bovine tuberculosis.
                </p>
                <div className="small_gap" />
                <p> 
                    Bovine tuberculosis is a bacterial infection which is known to affect cattle and badgers, as well as other mammals. 
                    It poses little risk to humans so long as we pasteurise milk before drinking it to reduce risk of transmission. 
                    However due to its high infection rate in cattle, when a case of the infection is found, often the whole herd must 
                    be tested or culled. The badger culling was initiated as a result of the high spread of bovine TB, however the 
                    evidence indicates that culling has not had an impact on the spread of the disease. 
                </p>     
                <div className="small_gap" />
                <p>
                    94% of bovine TB cases are spread between cattle and the rates of infection were reducing as a result of cattle 
                    measures before the badger culling was initiated. Recently a vaccination program has been funded by the government 
                    for badgers which will hopefully reduce further the spread of bovine TB and alleviate people's fears about the 
                    spread of the infection. 
                </p>
                <div className='gap' />
                <h1> 
                    Camera Trap Gallery
                </h1>
            </div>
            </div>
                <VideoGal species="badger"/>   
            <div className="frontpagetext" >
            <div className='frontpagetext-inner' >

                <h1> 
                    Want to learn more?
                </h1>
                <div className="small_gap" />
                <p> 
                    To continue learning about our different wildlife please continue with the links below:
                </p>
            </div>
            </div>
            <div className="trapLinks">
                <div className="imageContainer">
                    <Link to="/wildlife/foxes" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='fox_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Foxes</h4>
                    </div>
                    
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/deer" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='deer_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Deer</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/rabbits" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='rabbit_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Rabbits</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/birds" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='birds_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Birds</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/otherspecies" onClick={() => {window.scroll(0, 0);}}>
                        <img
                        src="https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/DSCF0035.JPG?alt=media&token=423f367b-3173-4047-88ca-a3b825cf9b08.png"
                        height={100}
                        alt="Other Species"
                        />
                    <div className="bannersmall">
                        <h4>Other Species</h4>
                    </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Badgers;