
import React, { useEffect } from 'react';
import 'aframe';
import 'aframe-particle-system-component';
import {Entity, Scene} from 'aframe-react';

const VideoPlayer = () => {
  const videoPath =
    'https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/360%20Footage%2F2023_08_31_14_13_33_injected.mp4?alt=media&token=22a72556-96ec-43e4-850a-2848e3d07a58';


  return (
    <a-scene>
      <a-assets>
      <video id="video" preload="auto"
               src= {videoPath}
               autoPlay
               crossOrigin="anonymous"></video>
      </a-assets>
      <a-videosphere
        rotation="0 -90 0"
        src="#video"
        visible="true"
        play-on-click>
      </a-videosphere>
      <a-camera>
        <a-entity
          position="0 0 -1.5"
          text="align: center;
                width: 6;
                wrapCount: 100;
                color: black;
                value: Click or tap to start video"
          hide-on-play="#video">
        </a-entity>
      </a-camera>
    </a-scene>
  );
};

const Camera = () => {
  return <VideoPlayer />;
};

export default Camera;



