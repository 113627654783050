import { MultilevelMenu } from "react-multilevel-menu";


const MobileMenu = () => {

    const menuItems = [
        {
        label: 'Home',
        link: '/',
        },
        {
        label: 'Farm Animals',
        items: [
            {
            label: 'Farm animals',
            link: '/farm_animals',
            },
            {
            label: 'Sheep',
            link: '/farm_animals/sheep',
            },
            {
            label: 'Goats',
            link: '/farm_animals/goats',
            },
            {
            label: 'Pigs',
            link: '/farm_animals/pigs',
            },
            {
            label: 'Alpacas',
            link: '/farm_animals/alpacas',
            },
            {
            label: 'Horses',
            link: '/farm_animals/horses',
            },
            {
            label: 'Bees',
            link: '/farm_animals/bees',
            },
        ],
        },
        {
        label: 'Wildlife',
        items: [
            {
            label: 'Wildlife',
            link: '/wildlife',
            },            
            {
            label: 'Badgers',
            link: '/wildlife/badgers',
            },
            {
            label: 'Foxes',
            link: '/wildlife/foxes',
            },
            {
            label: 'Deer',
            link: '/wildlife/deer',
            },
            {
            label: 'Rabbits',
            link: '/wildlife/rabbits',
            },
            {
            label: 'Birds',
            link: '/wildlife/birds',
            },
            {
            label: 'Other Species',
            link: '/wildlife/otherspecies',
            },
        ],
        },
        {
        label: 'Birds',
        items: [
            {
            label: 'Add a sighting',
            link: '/birds/sightings',
            },
            {
            label: 'View sightings',
            link: '/birds/view',
            },
            {
            label: 'Gallery',
            link: '/birds/gallery',
            },
        ],
        },
        {
        label: 'Photo galleries',
        items: [
            { 
                label: 'Gallery',
                link: '/gallery',
            },
            {
                label: 'Camera Traps',
                link: '/gallery/cameratraps'
            }
        ]
        },

        {
        label: '360 footage',
        items: [
            {
            label: '360 footage',
            link: '/camera/videoviewers',
            },
            {
            label: 'Iron Age',
            link: '/camera/ironage',
            },
            {
            label: 'Roman Era',
            link: '/camera/romanera',
            },
            {
            label: 'Modern Day',
            link: '/camera/modernday',
            },
    ]
        },
        {
        label: 'History',
        items: [
            {
            label: 'History',
            link: '/history',
            },
            {
                label: 'Maps',
                link: '/history/maps'
            },
        ],
        },
    ];

    
    const selectedItem = (event) => {
        console.log(event);
    };

    const config = {
        classlabel: "my-menu-class",
        listBackgroundColor: `rgb(194, 229, 166)`,
        fontColor: `rgb(0, 58, 112)`,
        highlightOnSelect: true,
        useDividers: false,
    };



    return (
    <div className="mobile-dropdown" >
        <MultilevelMenu 
        list={menuItems}
        configuration={config}
        selectedListItem={selectedItem}
        selectedLabel={selectedItem}
        />
    </div>
  );
};

export default MobileMenu;