import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CarouselCat from '../../components/CarouselOther';
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import { alpacasDesktop, alpacasMobile } from "../../db/public_images";

function Alpacas() {
    let jsonData = alpacasMobile
    if (window.screen.availWidth > 768) {
      jsonData = alpacasDesktop
    }
    return (
        <div>
        <CarouselCat imageList={jsonData} legend= "Want to learn about alpacas?" />
        <div className= "frontpagetext" >
        <div className='frontpagetext-inner' >
            <p>
                We have two breeds of alpaca on the farm, their main difference is their fleece texture. Our alpacas are very friendly animals 
                - you can ask our farm staff about walking or feeding them if you want to meet them more closely!
            </p>
            <div className="medium_gap" />
        <div className="speciesImages"> 
            <div>
            <CallSpeciesPhoto name='huacaya_face' species='' width='350px' />
            <div className="small_gap" />
                <h4> 
                    Huacaya Alpaca 
                </h4>
                <div className="small_gap" />
                <ul className="infoList"> 
                    <li><p> Have smoother, softer fur.</p></li>
                    <li><p> Recessive gene.</p></li>
                    <li><p> We have more huacaya.</p></li>
                </ul>
            </div>
            <div>
                <CallSpeciesPhoto name='suri_face' species='' width='350px' />
                <div className="small_gap" />
            <h4>
                Suri Alpaca
            </h4>
            <div className="small_gap" />
            <ul className="infoList">
                <li><p> Curlier fur that has crinkles in it.</p></li>
                <li><p> Dominant gene.</p></li>
                <li><p> We have fewer suri.</p></li>
            </ul>
            </div>
        </div>
        <div className="small_gap" />
        <p>
            Alpacas mostly eat grass and hay in their fields. Low quality grass which has fewer nutrients is best as alpacas evolved in the 
            Andes mountains so rich food can be hard for them to digest. Similar to cows and sheep they take a long time to digest their food, 
            using multiple stomach compartments - although alpacas have three not four! Their evolution in mountain ranges means they digest 
            very efficiently, requiring little food and water to survive. 
        </p>
        <div className="small_gap"/>
        <p> 
            Alpaca fleeces are different to sheeps wool in that they contain no lanolin. This means alpaca wool is softer and easier to work 
            with. Furthermore, lanolin is the cause of wool allergies or sensitivities. As alpacas do not produce lanolin, their wool is 
            hypoallergenic so can be worn by people who typically have wool allergies. 
        </p>
            <div className="medium_gap" />
            <h1> 
                Want to learn more?
            </h1>
            <div className="small_gap" />
            <p> 
                To continue learning about our different farm animals please continue with the links below:
            </p>
        </div>
        </div>
        <div className="trapLinks">
        <div className="imageContainer">
            <Link to="/farm_animals/sheep" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='sheep_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Sheep</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/goats" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='goat_pygmy_baby_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Goats</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/pigs" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='pig_bob_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Pigs</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/horses" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='horse_sunset_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Horses</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/bees" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='beehives_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Bees</h4>
            </div>
            </Link>
        </div>
    </div>
    </div>
    );
}

export default Alpacas;