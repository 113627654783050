import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';


type Data = {
  id: string;
  species: string;
  date?: string;
  behaviour: string;
  number: string;
  comments: string;
  lat: number;
  long: number;
  src: string; // Add the photo field
};

const SpeciesTable: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/birdrec/get');
        const jsonData = await response.json();
        console.log('Fetched data:', jsonData);

        const dataWithDate = jsonData.map((item: Data) => ({
            ...item,
            date: item.date ? new Date(item.date).toLocaleDateString('en-GB') : undefined,
            photo: item.src, // Replace 'URL_TO_YOUR_IMAGE' with the URL or base64 encoded image of the photo for each item
          }));

        setData(dataWithDate);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredSpecies = data.filter(
    (data) =>
      data.species.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleImageThumbnailClick = (imageUrl: string) => {
    // Determine the index of the clicked image in the 'slides' array
    const clickedImageIndex = data.findIndex(item => item.src === imageUrl);
    setIndex(clickedImageIndex);
  }


  return (
    <div>
      <div className='searchboxcontainer'>
      <TextField 
        className='searchbox'
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      <div className='birdtablecontainer'>
      <div className='birdtable' >
      <Paper>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead className='tableheader'>
                <TableRow>
                <TableCell>Species</TableCell>
                <TableCell>Date of Sighting</TableCell>
                <TableCell>Behaviour</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Photo</TableCell> {/* Add the Photo column */}
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredSpecies.map((data, index) => (
                <TableRow key={index}>
                    <TableCell>{data.species}</TableCell>
                    <TableCell>{data.date}</TableCell>
                    <TableCell>{data.behaviour}</TableCell>
                    <TableCell>{data.number}</TableCell>
                    <TableCell>{data.comments}</TableCell>
                    <TableCell>
                    <img
                        src={data.src}
                        alt={data.species}
                        onClick={() => handleImageThumbnailClick(data.src)}
                        style={{ cursor: 'pointer', width: '80px', height: 'auto' }}
                    />
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
      </Paper>
      </div>
      </div>
      <Lightbox
        slides={data} // Create an array of image URLs with the appropriate property name
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div>
  );
};

export default SpeciesTable;
