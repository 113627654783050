import React, { useEffect, useState } from "react";

type Data = {
    src?: string,
    name?: string,
    type?: string,
    height?: number,
    width?: number,
    alt?: string,
}

function CallHistory ({name, type, width}) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<Data[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let jsonData = []
                if (name) {
                    const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/history?name=' + name);
                    jsonData = await response.json();
                }
                if (type) {
                    const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/history?type=' + type);
                    jsonData = await response.json();
                }

                console.log('Fetched data:', jsonData);
                setData(jsonData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [name]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            {data.map((item) => (
                <img src={item.src} width={width} alt={item.alt} />
            ))}
        </div>
    )
}

export default CallHistory; 