import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CarouselCat from '../../components/CarouselOther';
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import { goatsDesktop, goatsMobile } from "../../db/public_images";

function Goats() {
    let jsonData = goatsMobile
    if (window.screen.availWidth > 768) {
      jsonData = goatsDesktop
    }
    return (
        <div>
        <CarouselCat imageList={jsonData} legend= "Want to learn about goats?" />
        <div className= "frontpagetext" >
        <div className='frontpagetext-inner' >
            <p> 
                We have three breeds of goat on the farm. They are each unique and all very friendly and playful. Goats can be used for 
                milk or meat, but we just keep ours because we like to have them around the farm!
            </p>
            <p> 
                Goats are closely related to sheep so eat similar food to them and digest in the same way. They also have similar numbers of 
                young, giving birth to between one and three young. Their young are known as kids, the older males are called billys and the 
                females are does. 
            </p>
            <div className="medium_gap" />
            <div className="speciesImages"> 
                <div className="goatcolumn">
                <CallSpeciesPhoto name='goat_pygmy_crop.png' species='' width='240px' />
                    <div className="small_gap" />
                    <h4> 
                        Pygmy 
                    </h4>
                    <ul>
                        <li><p> Small - only reach around 50cm tall! </p></li>
                        <li><p> Males and females have tiny horns. </p></li>
                        <li><p> Males tend to have quite long beards.  </p></li>
                    </ul>
                </div>
                <div className="goatcolumn">
                    <CallSpeciesPhoto name='goat_anglonubian_crop' species='' width='240px' />
                <div className="small_gap" />
                <h4>
                    Anglo-Nubian
                </h4>
                <ul>
                    <li><p> Reach up to around 90cm tall. </p></li>
                    <li><p> Can have horns, ours do not. </p></li>
                    <li><p> Have long floppy ears. </p></li>
                </ul>
                </div>
                <div className="goatcolumn">
                    <CallSpeciesPhoto name='toggenburg' species='' width='240px' />
                <div className="small_gap" />
                <h4>
                    Toggenburg
                </h4>
                <ul>
                    <li><p> Can reach up to a metre tall! </p></li>
                    <li><p> Males and females can both grow horns. </p></li>
                    <li><p> Both sexes can also grow beards.  </p></li>
                </ul>
                </div>
            </div>
            <div className="medium_gap" />
            <h1> 
                Want to learn more?
            </h1>
            <div className="small_gap" />
            <p> 
                To continue learning about our different farm animals please continue with the links below:
            </p>
        </div>
        </div>
        <div className="trapLinks">
        <div className="imageContainer">
            <Link to="/farm_animals/sheep" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='sheep_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Sheep</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/pigs" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='pig_bob_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Pigs</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/alpacas" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='alpaca_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Alpacas</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/horses" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='horse_sunset_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Horses</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/bees" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='beehives_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Bees</h4>
            </div>
            </Link>
        </div>
        </div>
    </div>
    );
}

export default Goats;