import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CarouselCat from '../../components/CarouselOther';
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import { horsesDesktop, horsesMobile } from "../../db/public_images";

function Horses() {
    let jsonData = horsesMobile
    if (window.screen.availWidth > 768) {
      jsonData = horsesDesktop
    }
    return (
        <div>
        <CarouselCat imageList={jsonData} legend= "Want to learn about horses?" />
        <div className= "frontpagetext" >
        <div className='frontpagetext-inner' >
            <p> 
                On the farm we have some of our own horses, and others that board here. We have a sand arena for learning to ride, 
                and if you live in the village you may have seen the horses around or on some of the footpaths. During the day the horses 
                are taken outside to socialise and graze on fresh grass in the fields. During the evenings they come back into their barns 
                where they get to sleep in the warmth and are provided with extra hay. 
            </p>
            <div className="small_gap"/>
            <p> 
                Horses can be quite nervous, so please make sure that if you see them while out on the roads to give them a 
                wide berth and slow down. We don't want anyone getting hurt whilst out riding! If you see them around the farm however, 
                feel free to ask their rider if you can say hello and give them a stroke. 
            </p>
            <div className="small_gap"/>
            <p> 
                The farm has taken an interest in horses for a long time. During the mid 20th-century horse riding competitions were held 
                here by the Bowditch family who owned the farm for most of the century. These competitions brought riders from miles around 
                to show off their talents.
            </p>
            <div className="small_gap"/>
            <h1> 
                Want to learn more?
            </h1>
            <div className="small_gap"/>
            <p> 
                To continue learning about our different farm animals please continue with the links below:
            </p>
        </div>
        </div>
        <div className="trapLinks">
        <div className="imageContainer">
            <Link to="/farm_animals/sheep" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='sheep_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Sheep</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/goats" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='goat_pygmy_baby_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Goats</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/pigs" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='pig_bob_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Pigs</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/alpacas" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='alpaca_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Alpacas</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/bees" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='beehives_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Bees</h4>
            </div>
            </Link>
        </div>
        </div>
    </div>
    );
}

export default Horses;