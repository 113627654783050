import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import CarouselCat from "../../components/CarouselOther";
import VideoGal from "../../components/VideoGal";
import { rabbitDesktop, rabbitMobile } from "../../db/public_images";

function Rabbits() {
    let jsonData = rabbitMobile
    if (window.screen.availWidth > 768) {
      jsonData = rabbitDesktop
    }
    return (
        <div>
            <CarouselCat imageList={jsonData} legend='Want to learn about rabbits?' />
            <div className= "frontpagetext" >
            <div className='frontpagetext-inner' >
                <p>
                    We have lots of rabbits around the farm, these are European or common rabbits. The UK doesn't have any other rabbit species 
                    but it does have two species of hare, the common and brown. Hares tend to be larger than rabbits, and are less commonly seen. 
                </p>
                <div className="medium_gap"/>
                <h1>
                    Rabbit Homes
                </h1>
                <div className="small_gap" />
                <p>
                    Rabbits live in warrens all over the farm. You may have seen the entrances to their warrens while walking around. Social groups 
                    or families of up to thirty rabbits may live in a single warren. The females or does create nests in the burrows where they 
                    raise their young. Each doe can give birth to between three and seven young each year. 
                </p>
                <div className="medium_gap"/>
                <h1>
                    Rabbit Diet
                </h1>
                <div className="small_gap" />
                <p> 
                    Rabbits are herbivores and live primarily off grass and other plants. To absorb all the nutrients they use a different technique than sheep or 
                    goats. Once the food has passed through their digestive system the first time, they eat the special poo, called caecotrophs,
                    and redigest the matter to extract the rest of the nutrients. This ensures that the rabbits gain as much nutritional value 
                    as possible from their food. 
                </p>
                <div className='medium_gap' />
                <h1> 
                    Camera Trap Gallery
                </h1>
            </div>
            </div>
                <VideoGal species="bunny"/>   
            <div className="frontpagetext" >
            <div className='frontpagetext-inner' >
                <h1> 
                    Want to learn more?
                </h1>
                <div className="small_gap" />
                <p> 
                    To continue learning about our different wildlife please continue with the links below:
                </p>
            </div>
            </div>
            <div className="trapLinks">
                <div className="imageContainer">
                    <Link to="/wildlife/badgers" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='badger_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Badgers</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/foxes" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='fox_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Foxes</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/deer" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='deer_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Deer</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/birds" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='birds_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Birds</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/otherspecies" onClick={() => {window.scroll(0, 0);}}>
                        <img
                        src="https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/DSCF0035.JPG?alt=media&token=423f367b-3173-4047-88ca-a3b825cf9b08.png"
                        height={100}
                        alt="Other Species"
                        />
                    <div className="bannersmall">
                        <h4>Other Species</h4>
                    </div>
                    </Link>
                </div>
            </div>
        </div> 
    );
}

export default Rabbits;