import './App.css';
import CarouselCat from './components/CarouselOther';
import HistoryMap from './components/HistoryMaps';
import { Link } from 'react-router-dom';
import { landscapeDesktop, landscapeMobile } from './db/public_images';

export default function Home() {
  let jsonData = landscapeMobile
  let legend = "Welcome to BCF Educational site!"
  if (window.screen.availWidth > 768) {
    jsonData = landscapeDesktop
    legend = "Welcome to Bishop's Court Farm Educational site!"
  }

return (
  <div>
      <CarouselCat imageList = {jsonData} legend={legend} />
      {/* <CarouselMob species='' category='landscape' legend="Welcome to BCF Educational site!" /> */}
      <div className = "frontpagetext">
        <div className='frontpagetext-inner' >
        <p> 
          Bishop's Court farm is a place steeped in history. People have lived around the farm and the village for over 6000 years. There 
          are crop marks and old pottery all over the land that have been discovered during arcaheological digs. Much of the land would 
          have been used for farming throughout this period. On this website all about the history of the farm, the ecology and 
          the animals we keep. 
        </p>
        <div className='small_gap' /> 
        <p> 
          Please use our map below to have an explore of the farm and see how the farm 
          and village have changed over time. We have several old maps from different periods overlayed below which you can explore. 
          The map shows where the different animal species are kept. You can also take a look at some photos we have taken around the farm.
        </p>
        </div>
      </div>
      <HistoryMap />
      <div className = "frontpagetext">
      <div className="frontpagetext-inner">
        <p>
          The layout of much of the village and surrounding fields have remained similar for hundreds if not thousands of 
          years. One big difference you will notice is the appearance of the first gravel lakes in the 1960s map. They 
          continued to be extracted later into the decade, leaving us with the lakes around the village that we have today.
        </p>
        <div className='medium_gap' />
        <h1> The Future</h1>
        <div className='small_gap'/>
        <p>
          As you can see from our future map we have some changes in the works. We are already fully organic - this means that we try to use 
          as few chemicals on our land as possible, and do not medicate our animals unless they are ill to reduce spread of antibiotic 
          resistance. Each year we are assessed by the Soil Association to ensure that our organic practices are up to standard. We hope that 
          being organic will improve our biodiversity and attract more insects and plantlife to the land. 
        </p>
        <div className='small_gap'/>
        <p> 
          We are taking further measures to improve the land and bring more wildlife. We are replenishing many of the hedgerows 
          and planting new sections of hedge where they were previously removed. Starting in Autumn 2023 we will be planting around 14 km 
          of hedgerows in this venture. We are also going to plant more trees along the riverbank and around the larger lake. 
          We hope the root structures from more trees would strengthen the banks, whilst also creating new habitats for 
          different animals and insects. 
        </p>
        <div className='small_gap'/>
        <p>
          We also want to dedicate several areas of land to village use by creating village parks and meadows. The scheduled 
          ancient monument covering the Dyke Hills and surrounding area has historically been used by villagers but we want to 
          make this official. The area from the Dykes to Days Lock will be a village park area, welcoming picnickers and having 
          fewer restrictions to footpaths. The field on the corner of Abingdon Road and the High Street will also be for village 
          use, giving a more nature filled space than the current village green. 
        </p>
        <div className='small_gap'/>
        <p>
          If you want to learn more about the history of the farm and the village <Link to='/history' onClick={() => {window.scroll(0, 0);}}>head to our history section</Link>.
        </p>
        <div className='small_gap' /> 
        <p> 
          To learn more about the wildlife on the farm or see some photos we have taken then <Link to='/wildlife' onClick={() => {window.scroll(0, 0);}}>check out our wildlife section</Link>.
        </p>
      </div>
      </div>
  </div>
  );
}

