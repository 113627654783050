import './footer.css'
import { menuItems } from './menuItemsFooter';
import { Link } from 'react-router-dom';
import FooterMob from './FooterMob';

const Footer = () => {
  return (
    <div>
      <div className='footermob'>
        <FooterMob /> 
      </div>
    <div className="footer">
    <div className='columnContainerfoot'>
      <div className='column'>
        <div className="navbar">
        <div className='small_gap' />
          <h2> Links </h2>
          <div className='medium_gap' />
            {menuItems.map((menu) => (
              <button className='button'
                type="button"
                aria-haspopup="menu"
                >
                <a href={menu.url}>{menu.title}</a>
                  <span
                    className={
                      menu.url ? " custom" : ""
                    }
                  />
              </button>
          ))}
        </div>     
      </div> 
      <div className='column' >
        <div className="navbarlogo">
            <a href="/" className="logo">
            <img src="/logo/BCF_Logo.webp" width='150rem' alt='' />
            </a>
        </div>
      </div>
      <div className='column' >
        <div className="navbaraddress">
        <div className='small_gap' />
          <h2> Contact us</h2>
        <div className='medium_gap' />
            <h3>91 High Street,</h3>
            <h3>Dorchester-on-Thames,</h3>
            <h3>Oxfordshire</h3>
            <div className='small_gap' />
            <h3>Contact us by email:</h3>
            <a href='mailto:izzy@bishopscourtfarm.com/' >
              <h3>izzy@bishopscourtfarm.com</h3>
            </a>           
            <div className='small_gap' />
            <h3>For our main website please go to:</h3>
            <Link to='https://www.bishopscourtfarm.com/' >
              <h3>bishopscourtfarm.com</h3>
            </Link>
        </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Footer;