import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CarouselCat from '../../components/CarouselOther';
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import { sheepDesktop, sheepMobile } from "../../db/public_images";

function Sheep() {
    let jsonData = sheepMobile
    if (window.screen.availWidth > 768) {
      jsonData = sheepDesktop
    }
    return (
        <div>
        <CarouselCat imageList={jsonData} legend= "Want to learn about sheep?" />
        <div className= "frontpagetext" >
        <div className='frontpagetext-inner' >
            <p> 
                We have six breeds of sheep on the farm. Have a look at the pictures below and you can see the differences between them.
                They vary in colour and in wool texture, as well as the shape of their faces. If you come to the farm you might see some 
                that don't exactly resemble one of the breeds - this happens when we cross two of the different breeds.  
            </p>
            <div className="small_gap"/>
            <p> 
                All our sheep live outside in the fields, feeding off the grass and keeping it mown for us! Like cows, they have four 
                separate stomach compartments as grass is difficult to digest. After eating their food it gets regurgitated and chewed more,
                giving more time for digestion and absorption of all the nutrients. 
            </p>
            <div className="medium_gap" />
            <div className="speciesImages"> 
              <div>
                <CallSpeciesPhoto name='lleyn1.png' species='' width='220px' />
                <h4> 
                    Lleyn 
                </h4>
                <ul className='list3col'>
                <li> <p>All white wool, slender body. </p></li>
                <li> <p>Often produce more lambs and are able to care for them well. </p></li>
                <li> <p>Lower disease risk than some other breeds. </p></li>
                </ul>       
                </div>
                <div>
                    <CallSpeciesPhoto name='ncm1.png' species='' width='220px' />
                <h4>
                    North Country Mule
                </h4>
                <ul className='list3col'>
                    <li> <p>Distinctive mottled black and white pattern on their face and legs. </p></li>
                    <li> <p>Good mothers that produce lots of milk and raise strong lambs. </p></li>
                    <li> <p>Lambs are good for meat. </p></li>
                </ul>
                </div>
                <div>
                    <CallSpeciesPhoto name='shropshire2.png' species='' width='220px' />
                <h4>
                    Shropshire
                </h4>
                <ul className='list3col'>
                    <li> <p> Black face and legs, but fleece grows over their heads. </p></li>
                    <li> <p> Very durable species that survive harsh conditions. </p></li>
                </ul>
                </div>
                <div>
                    <CallSpeciesPhoto name='suffolk1.png' species='' width='220px' />
                <h4>
                    Suffolk
                </h4>
                <ul className='list3col'>
                    <li> <p>Black face and legs, unlike shropshire their fleece does not cover their heads. </p></li>
                    <li> <p>Lambs grow quickly. </p></li>
                    <li> <p>Good for mutton. </p></li>
                </ul>
                </div>
                <div>
                    <CallSpeciesPhoto name='Texel1.png' species='' width='220px' />
                <h4>
                    Texel
                </h4>
                <ul className='list3col'>
                    <li> <p>Wide head with white face, and black nose. </p></li>
                    <li> <p>Rectangular, muscly body. </p> </li>
                    <li> <p>Lambs are very lean, good for meat. </p></li>
                </ul>
                </div>
                <div>
                    <CallSpeciesPhoto name='zwartble1.png' species='' width='220px' />
                <h4>
                    Zwartble
                </h4>
                <ul className='list3col'>
                    <li> <p>Black face with a white stripe down the center, black legs with white socks.</p></li>
                    <li> <p>Fleece is black but bleaches to become lighter brown in the sun. </p></li>
                    <li> <p>Strong milk producers, in some places used for dairy. </p></li>
                </ul>
                </div>
              </div> 
            <div className="medium_gap" />
            <h1> 
                Want to learn more?
            </h1>
            <div className="small_gap" />
            <p> 
                To continue learning about our different farm animals please continue with the links below:
            </p>
        </div>
        </div>
        <div className="trapLinks">
        <div className="imageContainer">
            <Link to="/farm_animals/goats" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='goat_pygmy_baby_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Goats</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/pigs" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='pig_bob_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Pigs</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/alpacas" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='alpaca_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Alpacas</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/horses" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='horse_sunset_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Horses</h4>
            </div>
            </Link>
        </div>
        <div className="imageContainer">
            <Link to="/farm_animals/bees" onClick={() => {window.scroll(0, 0);}}>
                <CallSpeciesPhoto name='beehives_redirect' species='' width='178px' />
            <div className="bannersmall">
                <h4>Bees</h4>
            </div>
            </Link>
        </div>
        </div>
    </div>
    );
}

export default Sheep;