import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import CarouselCat from "../../components/CarouselOther";
import VideoGal from "../../components/VideoGal";
import { deerDesktop, deerMobile } from "../../db/public_images";

function Deer() {
    let jsonData = deerMobile
    if (window.screen.availWidth > 768) {
      jsonData = deerDesktop
    }
    return (
        <div>
            <CarouselCat imageList={jsonData} legend='Want to learn about deer?' />
            <div className= "frontpagetext" >
            <div className='frontpagetext-inner' >
                <p>
                    Two species of deer primarily use the farm, out of six that are resident in the UK. We have muntjac deer, and roe 
                    deer. The muntjacs are identifiable by their smaller size, and the markings on their face. This distinguishes them 
                    from the larger roe deer. 
                </p>
                <div className="small_gap" />
                <p>
                    We primarily see them out foraging very early in the morning or quite late at night, with only occasional daytime 
                    sightings. So if you manage to catch a glimpse you are very lucky! We see them most in our slightly woodier areas 
                    of land eating from the trees and the grass. We aim to plant more bushes and trees in the coming months which will 
                    provide a greater source of food and habitat for these elegant species. 
                </p>
                <div className="medium_gap" />
                <div className="speciesImages"> 
                    <div>
                        <div className="photo">
                            <CallSpeciesPhoto name="deer_muntjac" species="" width="300rem" />
                        </div>
                        <div className="small_gap" />
                        <h4> 
                            Muntjac Deer 
                        </h4>
                        <ul className="infoList"> 
                            <p> <li> Up to 50cm tall and up to 90cm long.</li></p>
                            <p> <li> Two dark lines up the side of their face.</li></p>
                            <p><li> Males have very small antlers, up to 10cm.</li></p>
                            <p> <li> Originally brought to the UK from China in the early 1900s.</li></p>
                        </ul>
                    </div>
                    <div>
                        <div className="photo">
                            <CallSpeciesPhoto name="deer_roe" species="" width="300rem" />
                        </div>
                    <div className="small_gap" />
                    <h4>
                        Roe Deer
                    </h4>
                    <ul className="infoList">
                        <p><li> 65 to 75cm tall and up to 135cm long.</li></p>
                        <p><li> Do not have a tail.</li> </p>
                        <p><li> Male antlers are small, but larger than for muntjacs.</li></p>
                    </ul>
                    </div>
                </div> 
                <p></p>
                <p>
                    Deer can be beneficial for the land in smaller populations as they play an important part in woodland ecosystems. 
                    However they have no natural predators in the UK, since populations of lynx and wolves were decimated to the point 
                    of extinction hundreds of years ago. As a result, deer can overrun woodland areas and reduce biodiversity by feeding
                    excessively on young plants and saplings. 
                </p>
                <div className="small_gap" />
                <p> 
                    In some areas reintroductions of lynx and wolves have been proposed and trialed. Reintroductions of species can be 
                    controversial due to local communities or farmers fearing any negative effects of the new species. However, when 
                    carried out effectively, reintroductions can have highly positive impacts on the environment and ecology of a region. 
                    In cases such as lynx and wolves, the reintroduction can control the deer populations, aiding woodland diversity and 
                    growth.
                </p>              
                <div className='medium_gap' />
                <h1> 
                    Camera Trap Gallery
                </h1>
            </div>
            </div>
                <VideoGal species="deer"/>   
            <div className="frontpagetext" >
            <div className='frontpagetext-inner' >
                <h1> 
                    Want to learn more?
                </h1>
                <div className="small_gap" />
                <p> 
                    To continue learning about our different wildlife please continue with the links below:
                </p>
            </div>
            </div>
            <div className="trapLinks">
                <div className="imageContainer">
                    <Link to="/wildlife/badgers" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='badger_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Badgers</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/foxes" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='fox_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Foxes</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/rabbits" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='rabbit_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Rabbits</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/birds" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='birds_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Birds</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/otherspecies" onClick={() => {window.scroll(0, 0);}}>
                        <img
                        src="https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/DSCF0035.JPG?alt=media&token=423f367b-3173-4047-88ca-a3b825cf9b08.png"
                        height={100}
                        alt="Other Species"
                        />
                    <div className="bannersmall">
                        <h4>Other Species</h4>
                    </div>
                    </Link>
                </div>
            </div>
        </div> 
    );
}

export default Deer;