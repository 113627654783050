import React, { useEffect, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import PhotoAlbum, { Photo } from 'react-photo-album'; // Import Photo type
import { Link } from 'react-router-dom';
import CarouselCat from '../components/CarouselOther';
import { birdsDesktop, birdsMobile } from '../db/public_images';

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';


type Data = {
  id: string;
  species: string;
  date?: string;
  behaviour: string;
  number: string;
  comments: string;
  lat: number;
  long: number;
  src: string; // Add the photo field
};

const BirdGallery: React.FC = () => {
    const [data, setData] = useState<Data[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [index, setIndex] = useState(-1);
    const [searchTerm, setSearchTerm] = useState('');
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/birdrec/get');
          const jsonData = await response.json();
          console.log('Fetched data:', jsonData);
          setData(jsonData);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (isLoading) {
      return <p>Loading...</p>;
    }
  
    // Filter out data items without a valid src/photo field
    const filteredData = data.filter(item => item.src);

    const photos: Photo[] = filteredData.map(item => ({
        src: item.src,
        width: 1, // Set width to 0, or you can add the actual width if you have the data
        height: 1, // Set height to 0, or you can add the actual height if you have the data
      }));
  
    const handleImageThumbnailClick = (imageUrl: string) => {
      // Determine the index of the clicked image in the 'slides' array
      const clickedImageIndex = filteredData.findIndex(item => item.src === imageUrl);
      setIndex(clickedImageIndex);
    };

    let jsonData = birdsMobile
    if (window.screen.availWidth > 768) {
      jsonData = birdsDesktop
    }

    return (
      <div>
      <CarouselCat imageList={jsonData} legend='Species Data'/>
        <div className='frontpagetext' >
          <div className='frontpagetext-inner'>
            <h1> Bird Gallery</h1>
            <div className='small_gap' />
            <p> 
              Here are some of the pictures bird enthusiasts around the farm have submitted. Have a look through, and feel free 
              to <Link to='/birds/sightings' onClick={() => {window.scroll(0, 0);}}>submit your own</Link>. For more details about where 
              and when different species have been spotted <Link to='/birds/view' onClick={() => {window.scroll(0, 0);}}> 
              have a look here</Link>!
            </p>
          </div>
        </div>
      <>
        <PhotoAlbum
          photos={photos} // Use filtered data here
          layout="rows"
          targetRowHeight={150}
          spacing={0}
          padding={2}
          onClick={({ index }) => setIndex(index)}
        />
  
        <Lightbox
          slides={filteredData}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          // enable optional lightbox plugins
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
      </>
      </div>
    )
  };
  
  export default BirdGallery;
  