import React, { useEffect, useState } from 'react';
import '../App.css';
import SpeciesTable from '../components/BirdTable';
import BirdMap from '../components/BirdMap';
import { Link } from 'react-router-dom';
import CarouselCat from '../components/CarouselOther';
import { birdsDesktop, birdsMobile } from '../db/public_images';

function BirdsView() {

  let jsonData = birdsMobile
  if (window.screen.availWidth > 768) {
    jsonData = birdsDesktop
  }

  return (
    <div>
      <CarouselCat imageList={jsonData} legend='Species Data'/>
      <div className="form-box">
        <div className='frontpagetext'>
          <div className='frontpagetext-inner'>
          <p> 
            Are you interested in birds? Have a look at what has been seen around the farm! We have plenty of bird enthusiasts who come 
            from far and wide to see what birds we have passing through. If you are a bird watcher please feel free to contribute and upload 
            your own bird sightings <Link to='/birds/sightings' onClick={() => {window.scroll(0, 0);}}>here</Link>! We also have a map 
            showing the distribution of the different sightings at the bottom of this page. 
          </p>
        </div>
        </div>
        <SpeciesTable />
      </div>
      <div className='frontpagetext'>
        <div className='frontpagetext-inner'>
          <h1> Bird Sightings Map</h1>
          <div className='small_gap' />
          <p> 
            Below we have a map showing where all the different birds have been seen. If you click on the markers you will be able to 
            see which bird species was seen in that location. 
          </p>
        </div>
      </div>
      <div>
        <BirdMap />
      </div>
      <div className='gap'/>
    </div>
  );
}

export default BirdsView;
