import '../../App.css'
import { Link } from "react-router-dom";
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import CarouselCat from "../../components/CarouselOther";
import VideoGal from "../../components/VideoGal";
import { foxDesktop, foxMobile } from '../../db/public_images';

function Foxes() {
    let jsonData = foxMobile
    if (window.screen.availWidth > 768) {
      jsonData = foxDesktop
    }
    return (
        <div>
            <CarouselCat imageList={jsonData} legend='Want to learn about foxes?' />
            <div className= "frontpagetext" >
            <div className='frontpagetext-inner' >
                <p>
                    Our foxes are scattered in burrows throughout the farm. They are nocturnal mammals who tend to stay in their dens 
                    during the day, only venturing out at night to forage and hunt. Foxes can be useful to us on the farm, as 
                    rabbits are one of their primary food sources which helps keep the rabbit populations in check. 
                </p>
                <div className="medium_gap" />
                <h1>
                    Fox Homes
                </h1>
                <div className="small_gap" />
                <p>
                    Foxes live in burrows called dens which they dig out of the ground themselves, or repurpose from old badger setts. 
                    They often have several different dens within their territory and they can use all of these at different times. 
                    They live in family groups generally with one male (dog), one female (vixen), and their cubs. Sometimes females 
                    from previous litters will also remain with the family and help raise new generations of cubs.
                </p>
                <div className="medium_gap" />
                <h1>
                    Fox Diet
                </h1>
                <div className="small_gap" />
                <p>
                    Foxes are omnivorous, though their diets focus primarily on meat which they hunt. They are expert hunters of rabbits,
                    birds and rodents. They also eat amphibians, worms and carrion, as well as berries and seeds. Unfortunately each year
                    during lambing season, we lose several lambs to foxes. However, we are able to reduce this 
                    threat by keeping alpacas in the sheep fields. Alpacas care for their herds, and if they saw a fox targeting a 
                    lamb they would be likely to protect the lambs or their own young (cria) with one of their vicious kicks!
                </p>
                <div className='medium_gap' />
                <h1> 
                    Camera Trap Gallery
                </h1>
            </div>
            </div>
                <VideoGal species="fox"/>   
            <div className="frontpagetext" >
            <div className='frontpagetext-inner' >
                <h1> 
                    Want to learn more?
                </h1>
                <div className="small_gap" />
                <p> 
                    To continue learning about our different wildlife please continue with the links below:
                </p>
            </div>
            </div>
            <div className="trapLinks">
                <div className="imageContainer">
                    <Link to="/wildlife/badgers" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='badger_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Badgers</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/deer" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='deer_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Deer</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/rabbits" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='rabbit_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Rabbits</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/birds" onClick={() => {window.scroll(0, 0);}}>
                        <CallSpeciesPhoto name='birds_redirect' species='' width='178px' />
                    <div className="bannersmall">
                        <h4>Birds</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/wildlife/otherspecies" onClick={() => {window.scroll(0, 0);}}>
                        <img
                        src="https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/DSCF0035.JPG?alt=media&token=423f367b-3173-4047-88ca-a3b825cf9b08.png"
                        height={100}
                        alt="Other Species"
                        />
                    <div className="bannersmall">
                        <h4>Other Species</h4>
                    </div>
                    </Link>
                </div>
            </div>
        </div>  
    );
}

export default Foxes;