export const menuItems = [
  {
    title: <h3>Home</h3>,
    url: '/',
  },
  {
    title: <h3>Farm animals</h3>,
    url: '/farm_animals',
        submenu: [
          {
            title: <h4>Sheep</h4>,
            url: '/farm_animals/sheep',
          },
          {
            title: <h4>Goats</h4>,
            url: '/farm_animals/goats',
          },
          {
            title: <h4>Pigs</h4>,
            url: '/farm_animals/pigs',
          },
          {
            title: <h4>Alpacas</h4>,
            url: '/farm_animals/alpacas',
          },
          {
            title: <h4>Horses</h4>,
            url: '/farm_animals/horses',
          },
          {
            title: <h4>Bees</h4>,
            url: '/farm_animals/bees',
          },
        ],
  },
  {
    title: <h3>Wildlife</h3>,
    url: '/wildlife',
        submenu: [
          {
            title: <h4>Badgers</h4>,
            url: '/wildlife/badgers',
          },
          {
            title: <h4>Foxes</h4>,
            url: '/wildlife/foxes',
          },
          {
            title: <h4>Deer</h4>,
            url: '/wildlife/deer',
          },
          {
            title: <h4>Rabbits</h4>,
            url: '/wildlife/rabbits',
          },
          {
            title: <h4>Birds</h4>,
            url: '/wildlife/birds',
          },
          {
            title: <h4>Other Species</h4>,
            url: '/wildlife/otherspecies',
          },
        ],
  },
  {
    title: <h3>Bird Sightings</h3>,
    url: '/birds/sightings',
      submenu: [
        {
          title: <h4>Add a sighting</h4>,
          url: '/birds/sightings',
        },
        {
          title: <h4>View sightings</h4>,
          url: '/birds/view',
        },
        {
          title: <h4>Gallery</h4>,
          url: '/birds/gallery',
        },
      ],
    },
    {
    title: <h3>Gallery</h3>,
    url: '/gallery',
    submenu: [
      {
        title: <h4>Camera Traps</h4>,
        url: '/gallery/cameratraps'
      },
    ],
    },
    {
    title: <h3>360 Footage</h3>,
    url: '/camera/videoviewers',
    submenu: [

        {
          title: <h4>Iron Age</h4>,
          url: '/camera/ironage',
        },
        {
          title: <h4>Roman Era</h4>,
          url: '/camera/romanera',
        }, 
        {
          title: <h4>Modern Day</h4>,
          url: '/camera/modernday',
        },
      ],
    },
    {
      title: <h3>History</h3>,
      url: '/history',
      submenu: [
        {
          title: <h4>History Maps</h4>,
          url: '/history/maps',
        },
      ]
    },
];