// LANDSCAPE PHOTOS

const landscapeDesktop  = 
        [
            {"src":"/desktop/landscape/public_clumps+lake.webp", "id": "1", "alt": "landscape_1"},
            {"src":"/desktop/landscape/public_sunrise+view.webp", "id": "2", "alt": "landscape_2"}
        ]

const landscapeMobile  = 
        [
            {"src":"/mobile/landscape/CarouselImagesMobile-19.webp", "id": "1", "alt": "landscape_1"},
            {"src":"/mobile/landscape/farmdronepic.webp", "id": "2", "alt": "landscape_2"},
            {"src":"/mobile/landscape/sunriselake.webp", "id": "3", "alt": "landscape_3"},
            {"src":"/mobile/landscape/sunrisemist.webp", "id": "4", "alt": "landscape_4"}
        ]


// FARM ANIMAL PHOTOS

const farmanimalsDesktop  = 
        [
            {"src":"/desktop/farmanimals/sheep/sheep in the sunrise.webp", "id": "1", "alt": "sheep_1"},
            {"src":"/desktop/farmanimals/horses/horse large min.webp", "id": "2", "alt": "horses_1"},
            {"src":"/desktop/farmanimals/sheep/two sheep in the snow.webp", "id": "3", "alt": "sheep_2"},
            {"src":"/desktop/farmanimals/alpacas/alpacas in the shade.webp", "id": "4", "alt": "alpacas_1"},
            {"src":"/desktop/farmanimals/goats/pygmy goat.webp", "id": "5", "alt": "goat_1"},
            {"src":"/desktop/farmanimals/pigs/piglets.webp", "id": "6", "alt": "pig_1"},
            {"src":"/desktop/farmanimals/bees/a row of beehives on the farm_.webp", "id": "7", "alt": "bee_1"},
            {"src":"/desktop/farmanimals/sheep/lambs in the barn.webp", "id": "8", "alt": "sheep_3"},
            {"src":"/desktop/farmanimals/alpacas/alpacas_close.webp", "id": "9", "alt": "alpacas_2"},
            {"src":"/desktop/farmanimals/sheep/sheep and lambs in field.webp", "id": "10", "alt": "sheep_4"},
            {"src":"/desktop/farmanimals/goats/all_goats.webp", "id": "11", "alt": "goat_2"},
            {"src":"/desktop/farmanimals/pigs/pigs_all.webp", "id": "12", "alt": "pig_2"},
        ]

const farmanimalMobile =         
        [
            {"src":"/mobile/farmanimals/sheep/Sheep by the lake.webp", "id": "1", "alt": "sheep_1"},
            {"src":"/mobile/farmanimals/horses/horse_min.webp", "id": "2", "alt": "horses_1"},
            {"src":"/mobile/farmanimals/sheep/sheep in the snow.webp", "id": "3", "alt": "sheep_2"},
            {"src":"/mobile/farmanimals/bee/beehives.webp", "id": "4", "alt": "bee_1"},
            {"src":"/mobile/farmanimals/pigs/pigs-mobile.webp", "id": "5", "alt": "pig_1"},
            {"src":"/mobile/farmanimals/alpaca/alpaca-mobile.webp", "id": "6", "alt": "alpaca_1"},
            {"src":"/mobile/farmanimals/goat/goat.webp", "id": "7", "alt": "goat_1"},
            {"src":"/mobile/farmanimals/other/other-mobile.webp", "id": "8", "alt": "other_1"}
        ]


const sheepDesktop = 
        [
            {"src":"/desktop/farmanimals/sheep/sheep in the sunrise.webp", "id": "1", "alt": "sheep_1"},
            {"src":"/desktop/farmanimals/sheep/two sheep in the snow.webp", "id": "3", "alt": "sheep_2"},
            {"src":"/desktop/farmanimals/sheep/lambs in the barn.webp", "id": "8", "alt": "sheep_3"},
            {"src":"/desktop/farmanimals/sheep/sheep and lambs in field.webp", "id": "10", "alt": "sheep_4"},
        ]

const sheepMobile =         
        [
            {"src":"/mobile/farmanimals/sheep/Sheep by the lake.webp", "id": "1", "alt": "sheep_1"},
            {"src":"/mobile/farmanimals/sheep/sheep in the snow.webp", "id": "3", "alt": "sheep_2"}
        ]


const pigsDesktop  = 
        [
            {"src":"/desktop/farmanimals/pigs/piglets.webp", "id": "6", "alt": "pig_1"},
            {"src":"/desktop/farmanimals/pigs/pigs_all.webp", "id": "6", "alt": "pig_2"},
        ]

const pigsMobile =         
        [
            {"src":"/mobile/farmanimals/pigs/pigs-mobile.webp", "id": "5", "alt": "pig_1"}
        ]


const horsesDesktop  = 
        [
            {"src":"/desktop/farmanimals/horses/horse large min.webp", "id": "2", "alt": "horses_1"},
        ]


const horsesMobile =         
        [
            {"src":"/mobile/farmanimals/horses/horse_min.webp", "id": "2", "alt": "horses_1"}
        ]



const goatsDesktop  = 
        [
            {"src":"/desktop/farmanimals/goats/pygmy goat.webp", "id": "5", "alt": "goat_1"},
            {"src":"/desktop/farmanimals/goats/all_goats.webp", "id": "11", "alt": "goat_2"}
        ]


const goatsMobile =         
        [
            {"src":"/mobile/farmanimals/goat/goat.webp", "id": "7", "alt": "goat_1"}
        ]


const alpacasDesktop  = 
        [
            {"src":"/desktop/farmanimals/alpacas/alpacas in the shade.webp", "id": "4", "alt": "alpacas_1"},
            {"src":"/desktop/farmanimals/alpacas/alpacas_close.webp", "id": "9", "alt": "alpacas_2"},

        ]

const alpacasMobile =         
        [
            {"src":"/mobile/farmanimals/alpaca/alpaca-mobile.webp", "id": "6", "alt": "alpaca_1"}
        ]




const beesDesktop  = 
        [
            {"src":"/desktop/farmanimals/bees/a row of beehives on the farm_.webp", "id": "7", "alt": "bee_1"}
        ]

const beesMobile =         
        [
            {"src":"/mobile/farmanimals/bee/beehives.webp", "id": "4", "alt": "bee_1"}
        ]



// WILDLIFE PHOTOS

const wildlifeDesktop  = 
        [
            {"src":"/desktop/wildlife/mammals/a fox, a roe deer, a muntjac.webp", "id": "1", "alt": "mammals_1"},
            {"src":"/desktop/wildlife/mammals/fox, badger, deer.webp", "id": "2", "alt": "mammals_2"},
            {"src":"/desktop/wildlife/birds/a swallow, a kingfisher, and a kestrel.webp", "id": "3", "alt": "bird_1"},
            {"src":"/desktop/wildlife/deer/three pictures of deer.webp", "id": "4", "alt": "deer_1"},
            {"src":"/desktop/wildlife/birds/four birds flying in the sunrise.webp", "id": "5", "alt": "bird_2"},
            {"src":"/desktop/wildlife/rabbit/three rabbit photos.webp", "id": "6", "alt": "rabbit_1"},
            {"src":"/desktop/wildlife/fox/three different foxes.webp", "id": "7", "alt": "fox_1"},
            {"src":"/desktop/wildlife/badger/three badger pictures.webp", "id": "8", "alt": "badger_1"}
        ]


const wildlifeMobile  = 
        [
            {"src":"/mobile/wildlife/deer/two_deer.webp", "id": "1", "alt": "deer_1"},
            {"src":"/mobile/wildlife/rabbit/two_rabbits.webp", "id": "2", "alt": "rabbit_1"},
            {"src":"/mobile/wildlife/fox/two_fox.webp", "id": "3", "alt": "fox_1"},
            {"src":"/mobile/wildlife/badger/two_badgers.webp", "id": "4", "alt": "badger_1"},
            {"src":"/mobile/wildlife/birds/CarouselImagesMobile-1.webp", "id": "5", "alt": "bird_1"},
            {"src":"/mobile/wildlife/other/other.webp", "id": "6", "alt": "other_1"}
        ]



const deerDesktop  = 
        [
            {"src":"/desktop/wildlife/deer/three pictures of deer.webp", "id": "4", "alt": "deer_1"}
        ]


const deerMobile  = 
        [
            {"src":"/mobile/wildlife/deer/two_deer.webp", "id": "1", "alt": "deer_1"}
        ]


const rabbitDesktop  = 
        [
            {"src":"/desktop/wildlife/rabbit/three rabbit photos.webp", "id": "6", "alt": "rabbit_1"}
        ]


const rabbitMobile  = 
        [
            {"src":"/mobile/wildlife/rabbit/two_rabbits.webp", "id": "2", "alt": "rabbit_1"}
        ]



const foxDesktop  = 
        [
            {"src":"/desktop/wildlife/fox/three different foxes.webp", "id": "7", "alt": "fox_1"}
        ]


const foxMobile  = 
        [
            {"src":"/mobile/wildlife/fox/two_fox.webp", "id": "3", "alt": "fox_1"}
        ]


const badgerDesktop  = 
        [
            {"src":"/desktop/wildlife/badger/three badger pictures.webp", "id": "8", "alt": "badger_1"}
        ]


const badgerMobile  = 
        [
            {"src":"/mobile/wildlife/badger/two_badgers.webp", "id": "4", "alt": "badger_1"}
        ]


const otherDesktop  = 
        [
            {"src":"/desktop/wildlife/mammals/a fox, a roe deer, a muntjac.webp", "id": "1", "alt": "mammals_1"},
        ]


const otherMobile  = 
        [
            {"src":"/mobile/wildlife/other/other.webp", "id": "6", "alt": "other_1"}
        ]



const birdsDesktop  = 
        [
            {"src":"/desktop/wildlife/birds/a swallow, a kingfisher, and a kestrel.webp", "id": "1", "alt": "bird_1"},
            {"src":"/desktop/wildlife/birds/four birds flying in the sunrise.webp", "id": "2", "alt": "bird_2"}
        ]

const birdsMobile  = 
        [
            {"src":"/mobile/wildlife/birds/CarouselImagesMobile-1.webp", "id": "1", "alt": "bird_1"}
        ]



export {
    landscapeDesktop, 
    landscapeMobile, 
    farmanimalsDesktop, 
    farmanimalMobile, 
    sheepDesktop,
    sheepMobile,
    pigsDesktop,
    pigsMobile,
    horsesDesktop,
    horsesMobile,
    goatsDesktop,
    goatsMobile,
    alpacasDesktop,
    alpacasMobile,
    beesDesktop,
    beesMobile,
    wildlifeDesktop,
    wildlifeMobile,
    birdsDesktop,
    birdsMobile,
    deerDesktop,
    deerMobile,
    rabbitDesktop,
    rabbitMobile, 
    foxDesktop,
    foxMobile,
    badgerDesktop,
    badgerMobile,
    otherDesktop,
    otherMobile
}