import React, { Component, ChangeEvent, KeyboardEvent } from "react";

interface AutocompleteProps {
  name: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  suggestions: string[];
  onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  isDropdown?: boolean; // Add isDropdown prop
}

interface AutocompleteState {
  activeSuggestion: number;
  filteredSuggestions: string[];
  showSuggestions: boolean;
  userInput: string;
}

class Autocomplete extends Component<AutocompleteProps, AutocompleteState> {
  static defaultProps: Partial<AutocompleteProps> = {
    suggestions: [],
    isDropdown: false, // Set default value for isDropdown prop
  };

  constructor(props: AutocompleteProps) {
    super(props);

    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
    };
  }

  onChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    const { suggestions, onChange } = this.props;
    const userInput = e.currentTarget.value;

    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput,
    });

    onChange(e as ChangeEvent<HTMLInputElement>);
  };



  onClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const { onClick } = this.props;

    this.setState(
      {
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: e.currentTarget.innerText,
      },
      () => {
        onClick(e);
      }
    );
  };


  onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const { onKeyDown } = this.props;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }

    onKeyDown(e);
  };

  render() {
    const {
      name,
      placeholder,
      value,
      suggestions,
      onClick,
      onKeyDown,
      isDropdown, // Get the isDropdown prop
    } = this.props;
    const {
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      userInput,
    } = this.state;

    let suggestionsListComponent: JSX.Element | null = null;

    if (showSuggestions && userInput && !isDropdown) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className = "";

              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={this.onClick} // Update this line
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    if (isDropdown) {
      suggestionsListComponent = (
        <select
          name={name}
          onChange={this.onChange}
          value={value}
        >
          <option value="">Select an option</option>
          {suggestions.map((suggestion) => (
            <option value={suggestion} key={suggestion}>
              {suggestion}
            </option>
          ))}
        </select>
      );
    }

    if (isDropdown) {
      return (
        <>
          {suggestionsListComponent}
        </>
      );
    }
    else {
      return (
        <>
          <input
            type="text"
            name={name}
            placeholder={placeholder}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            value={value}
          />
          {suggestionsListComponent}
        </>
      );
    }
  }
}

export default Autocomplete;
