import { Link } from "react-router-dom";
import CarouselCat from '../components/CarouselOther';
import { landscapeDesktop, landscapeMobile } from '../db/public_images';

function VideoModernDay () {
    let jsonData = landscapeMobile
    if (window.screen.availWidth > 768) {
      jsonData = landscapeDesktop
    }
    return (
        <div>
        <CarouselCat imageList = {jsonData} legend= "Modern Day" />
        <div className="frontpagetext"> 
        <div className='frontpagetext-inner' >
            <p> 
                Have you ever seen the farm from above? We have enjoyed taking out the farm drone and capturing 360 degree images displaying 
                the farm, village and surrounding area from high above. Have a look through the pictures below, remember you can move your 
                phone or pull the screen with your mouse to view every angle around you! 
            </p>
            <div>
                <div className="imageContainer">
                    <Link to="/camera/snugphoto">
                    <div className="linkbanner">
                        <h4>The Snug - minecraft recreation</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/camera/lakefieldpano">
                    <div className="linkbanner">
                        <h4>The Lake Field - drone footage</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/camera/lockpano">
                        <div className="linkbanner">
                            <h4> Days Lock - drone footage</h4>
                        </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/camera/scrapespano">
                        <div className="linkbanner">
                            <h4> The Scrapes - drone footage</h4>
                        </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/camera/farmpano">
                        <div className="linkbanner">
                            <h4> Farm buildings - drone footage</h4>
                        </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/camera/farmhousepano">
                        <div className="linkbanner">
                            <h4> Farm House - drone footage</h4>
                        </div>
                    </Link>
                </div>    
                <div className="imageContainer">
                    <Link to="/camera/allotmentpano">
                        <div className="linkbanner">
                            <h4> Above the allotments - drone footage</h4>
                        </div>
                    </Link>
                </div>  
                <div className="imageContainer">
                    <Link to="/camera/abovefarmpano">
                        <div className="linkbanner">
                            <h4> Farm from the north - drone footage</h4>
                        </div>
                    </Link>
                </div>              
            </div>
        </div>
        </div>
        </div>
    )
}

export default VideoModernDay; 