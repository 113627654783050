import { Link } from "react-router-dom";
import CarouselCat from '../components/CarouselOther';
import { landscapeDesktop, landscapeMobile } from '../db/public_images';

function VideoViewers () {
    let jsonData = landscapeMobile
    if (window.screen.availWidth > 768) {
      jsonData = landscapeDesktop
    }
    return (
        <div>
           <CarouselCat imageList = {jsonData} legend= "360 degree renders of the farm" />
        <div className="frontpagetext">
        <div className='frontpagetext-inner' >
            <p>
                We have made renders of how areas of the farm may have looked over time. Have a look through the links below to see 
                what different settlements and farm areas may have looked like throughout the ages. 
            </p>
            <div className="imageContainer">
                    <Link to="/camera/ironage">
                    <div className="linkbanner">
                        <h4>The Iron Ages</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/camera/romanera">
                    <div className="linkbanner">
                        <h4>The Roman Era</h4>
                    </div>
                    </Link>
                </div>
            <div className="imageContainer">
                    <Link to="/camera/modernday">
                    <div className="linkbanner">
                        <h4>Modern Day</h4>
                    </div>
                    </Link>
                </div>
            </div>
        </div>
        </div>
    )
}

export default VideoViewers;