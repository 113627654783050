import '../App.css'
import CarouselCat from '../components/CarouselOther';
import { Link } from "react-router-dom";
import CallSpeciesPhoto from "../components/CallSpeciesPhoto";
import { wildlifeDesktop, wildlifeMobile } from '../db/public_images';


function Wildlife() {
  let jsonData = wildlifeMobile
  if (window.screen.availWidth > 768) {
    jsonData = wildlifeDesktop
  }
  return (
    <div>
      <CarouselCat imageList = {jsonData} legend= "Wildlife!" />
      <div className = "frontpagetext">
      <div className='frontpagetext-inner' >
        <p> 
          Wildlife can be just as important for a farm as the farm animals. They help the ecosystem to thrive, which can help 
          the plants to grow and make the land healthier. Some of the wildlife you may see around the farm are foxes, badgers, rabbits, 
          deer, grass snakes, newts, frogs, lots of species of birds, and we even get the occasional otter!
        </p>
        <div className="small_gap" />
        <p>
          All of these species play a role in the environment, through their interactions with the land and with each other. The food chain 
          interactions mean some of these animals are reliant on each other for food. Many of the animals are herbivores which feed off grass 
          or berries that they forage around the farm. Foxes and badgers are omnivores, and might be seen eating rodents or amphibians as well 
          as berries, the foxes in particular enjoy the farm rabbits! 
        </p>
        <div className="small_gap" />
        <p> 
          To learn more about the wildlife that live around the farm along with our farm animals, have a look through the links below.
        </p>
      </div>
      </div>
      <div className="trapLinks">
        <div className="imageContainer">
          <Link to="/wildlife/badgers" onClick={() => {window.scroll(0, 0);}}>
            <CallSpeciesPhoto name='badger_redirect' species='' width='356px' />
          <div className="banner">
            <h1>Badgers</h1>
          </div>
          </Link>
        </div>
        <div className="imageContainer">
          <Link to="/wildlife/foxes" onClick={() => {window.scroll(0, 0);}}>
             <CallSpeciesPhoto name='fox_redirect' species='' width='356px' />
          <div className="banner">
            <h1>Foxes</h1>
          </div>
          </Link>
        </div>
        <div className="imageContainer">
          <Link to="/wildlife/deer" onClick={() => {window.scroll(0, 0);}}>
            <CallSpeciesPhoto name='deer_redirect' species='' width='356px' />
          <div className="banner">
            <h1>Deer</h1>
          </div>
          </Link>
        </div>
        <div className="imageContainer">
          <Link to="/wildlife/rabbits" onClick={() => {window.scroll(0, 0);}}>
            <CallSpeciesPhoto name='rabbit_redirect' species='' width='356px' />
          <div className="banner">
            <h1>Rabbits</h1>
          </div>
          </Link>
        </div>
        <div className="imageContainer">
          <Link to="/wildlife/birds" onClick={() => {window.scroll(0, 0);}}>
            <CallSpeciesPhoto name='birds_redirect' species='' width='356px' />
          <div className="banner">
            <h1>Birds</h1>
          </div>
          </Link>
        </div>
        <div className="imageContainer">
          <Link to="/wildlife/otherspecies" onClick={() => {window.scroll(0, 0);}}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/DSCF0035.JPG?alt=media&token=423f367b-3173-4047-88ca-a3b825cf9b08.png"
              height={200}
              alt="Other Species"
            />
          <div className="banner">
            <h1>Other Species</h1>
          </div>
          </Link>
        </div>
    </div>
  </div>
  );
};

export default Wildlife;
