import { Link } from "react-router-dom";
import CarouselCat from '../components/CarouselOther';
import { landscapeDesktop, landscapeMobile } from '../db/public_images';

function VideoIronAges () {
    let jsonData = landscapeMobile
    if (window.screen.availWidth > 768) {
      jsonData = landscapeDesktop
    }
    return (
        <div>
        <CarouselCat imageList = {jsonData} legend= "The Iron Ages" />
        <div className="frontpagetext">
        <div className='frontpagetext-inner' >
            <p> 
                A 360 degree video and a picture recreating what we believe the Iron Age settlement beyond the Dyke Hills may have looked 
                like is shown below. The Iron Age people built the Dyke Hills to protect their land. They went from the river Thames in 
                the west, all the way to the river Thame in the east. Given how the Dykes still flood so intensely nowadays, it is 
                believed that they were built so that the water would have flowed between the hills, 
                forming a connection between the Thames and Thame, and forming a protective barrier for the town. This meant the 
                population at the Dyke Hills were protected by water on all four sides. 
            </p>
            <div className="small_gap" />
            <p> 
                Castle hill or Sinodun hill - part of Wittenham Clumps - was also an Iron Age settlement. This was smaller than the settlement 
                at the Dyke Hills, which was more akin to a city. The settlement on the clumps was likely more of a protective fort, built 
                up high so that they could see invaders coming from far away. 
            </p>
            <div className="small_gap" />
            <p> 
                Iron Age houses were typically circular structures made of wattle and daub, which is where twigs and sticks are woven 
                together, and then daubed with mud 
                to glue them together and give protection from the elements. Their roofs were made of straw, similar to modern day thatch. Inside 
                their houses they would have had a fire in the middle to cook on and keep them warm. The smoke from this left through the thatch, a bit of 
                a fire hazard! They would have had straw beds to sleep on situated around the fire. 
            </p>
            <div className="small_gap" />
            <p> 
                Iron Age people would have kept livestock and farmed to supply themselves with food. They also had equipment for 
                constructing tools and weapons and making their own clothes. Some common jobs would have been carpenters, potters and 
                metalworkers, as iron working was widespread. 
            </p>
            <div className="small_gap" />
            <div>
                <div className="medium_gap" />
                <div className="imageContainer">
                    <Link to="/camera/ironagephoto">
                    <div className="linkbanner">
                        <h4>Iron Age Reconstruction - Photo</h4>
                    </div>
                    </Link>
                </div>
                <div className="imageContainer">
                    <Link to="/camera/ironagevideo">
                    <div className="linkbanner">
                        <h4>Iron Age Reconstruction - Video</h4>
                    </div>
                    </Link>
                </div>
            <div className="medium_gap" />
            </div>
        </div>
        </div>
        </div>
    )
}

export default VideoIronAges; 