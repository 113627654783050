
import React, { useEffect } from 'react';
import 'aframe';
import 'aframe-particle-system-component';
import {Entity, Scene} from 'aframe-react';

const ImageScene = () => {
    const imagePath = 'https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/360%20Footage%2F2023-08-31_12.27.44.png?alt=media&token=3b40cbf3-0ae6-4775-aa4c-1e00f5934ff6'; // Replace with your image path
  
    return (
      <Scene>
        <a-assets>
          <img id="image" src={imagePath} alt="3D Image" />
        </a-assets>
        <a-sky src="#image"></a-sky>
        <a-camera position="0 1.6 0">
          <a-cursor></a-cursor>
        </a-camera>
      </Scene>
    );
  };

const Image = () => {
  return <ImageScene />;
};

export default Image;
