import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import '../App.css';
import Button from "../components/CustomButtonComponent";
import onInfoWindowOpen from "../components/InfoWindowEx";

type Data = {
  id: string;
  species: string;
  date?: string;
  behaviour: string;
  number: string;
  comments: string;
  lat: number;
  long: number;
};

export default function BirdMap() {
  const mapRef = React.useRef<any>(null);
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clickedData, setClickedData] = useState<Data | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/birdrec/get');
        const jsonData = await response.json();
        console.log('Fetched data:', jsonData);
        setData(jsonData); // Set the fetched data to state
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDNqnMDVPMJ8cMCYAqiYZjj3tJqWiNXsAE"
  });

  const onLoad = React.useCallback(
    (mapInstance) => {
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach((item) => {
        bounds.extend(
          new window.google.maps.LatLng(item.lat, item.long)
        );
      });
      mapRef.current = mapInstance;
      mapInstance.fitBounds(bounds);
    },
    [data]
  );

  const onClickMarker = (dataID: string) => {
    const clicked = data.find((item) => item.id === dataID);
    setClickedData(clicked || null);
  };

  return (
    <div className="history_map">
      {isLoaded && (
        <GoogleMap
          center={{ lat: 51.64300944250453, lng: -1.172009060882794 }}
          zoom={14}
          mapContainerStyle={{ height: '100%', width: '100%' }}
          options={{
            streetViewControl: false,
            mapTypeId: window.google.maps.MapTypeId.SATELLITE, // Added 'window.' to access the google object
            mapTypeControl: false,
          }}
        >
          {data.map((item) => (
            <Marker
              key={item.id}
              onClick={() => onClickMarker(item.id)}
              position={{
                lat: item.lat,
                lng: item.long
              }}
            />
          ))}
          {clickedData && (
            <InfoWindow
              position={{
                lat: clickedData.lat,
                lng: clickedData.long
              }}
              onCloseClick={() => setClickedData(null)}
            >
              <h1>{clickedData.species}</h1>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
}