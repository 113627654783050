import React, { useEffect, useState } from "react";

type CurrentLocation = {
  latitude: number;
  longitude: number;
};

const Location = () => {
  const [currentPosition, setCurrentPosition] = useState<CurrentLocation>();

  useEffect(() => {
    const getCurrentPosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentPosition({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getCurrentPosition();
  }, []);

  // Export the current location as a function
    return currentPosition;

};

export default Location;
