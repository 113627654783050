import VideoPlayer from "../../components/360VideoPlayer";
import Call360footage from "../../components/Call360footage";
import { useNavigate } from "react-router-dom";


function RomanVideo() {
    const videoPath = 'https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/360%20Footage%2Fcompressed_256_crf_32_medium_full.mp4?alt=media&token=887b681a-65b8-40f0-ae3a-853716efcdaf';

    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `/camera/romanera`; 
      navigate(path);
      window.location.reload();
    }

return (
    <div>
    <div style={{ position: 'relative' }}>
        <VideoPlayer videoPath={videoPath} />
        <button onClick={routeChange}
            style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                background: 'transparent',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
                color: 'white', 
            }}
        >
            X
        </button>
    </div>  
    </div>  
)
}

export default RomanVideo;