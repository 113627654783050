import React, { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../App.css';

type Data = {
    id: string,
    src: string,
    species?: string,
    category?: string,
}

function CarouselCat({ category, species, legend }) {
    const [data, setData] = useState<Data[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let jsonData = []
                let backEndUrl = 'https://farm-website-backend-6924ec883f42.herokuapp.com/carouselimagesmob'
                console.log(window.screen.availWidth)
                if (window.screen.availWidth > 768) {
                    console.log('test')
                    backEndUrl = 'https://farm-website-backend-6924ec883f42.herokuapp.com/carouselimages'
                }
            
                if (category) {
                    const response = await fetch(backEndUrl + '?category=' + category);
                    jsonData = await response.json();
                } else if (species) {
                    const response = await fetch(backEndUrl + '?species=' + species);
                    jsonData = await response.json();
                } else {
                    // Fetch all carousel images if neither category nor species is specified
                    const response = await fetch(backEndUrl);
                    jsonData = await response.json();
                }

                console.log('Fetched data:', jsonData);
                setData(jsonData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [category]);

    if (isLoading) {
        return <div className="big_space"/>
    }

    return (
        <div className="carouselfull" >
        <Carousel autoPlay showThumbs={false} interval={4000} infiniteLoop>
            {data.map((item) => (
                <div key={item.id} className="custom-carousel">
                    <a>
                        <img src={item.src} alt={item.species} />
                    </a>
                    <div className="legendStyle">
                        <h1>{legend}</h1>
                    </div>
                </div>
            ))}
        </Carousel>
        </div>
    );
}

export default CarouselCat;
