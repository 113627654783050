import React, { useEffect, useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Link } from 'react-router-dom';
import CarouselCat from '../components/CarouselOther';
import { birdsDesktop, birdsMobile } from '../db/public_images';

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

type Data = {
  id: string;
  name: string;
  species: string;
  width: number;
  height: number;
  src: string
};

const Component: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(-1);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://farm-website-backend-6924ec883f42.herokuapp.com/image/all');
        const jsonData = await response.json();
        console.log('Fetched data:', jsonData);
        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


  let jsonData = birdsMobile
  if (window.screen.availWidth > 768) {
    jsonData = birdsDesktop
  }

  return (
    <>
    <CarouselCat imageList={jsonData} legend='Photo Gallery' />
    <div className='frontpagetext'>
      <div className='frontpagetext-inner'>
        <p> 
          Here are some of the photos we have taken around the farm. If you are interested in seeing some of our other wildlife up close 
          you can also take a look at our <Link to="/gallery/cameratraps" onClick={() => {window.scroll(0, 0);}}>camera trap gallery</Link>. 
        </p>
        <div className='small_gap'/>
        <p> 
          We also have a gallery just for the birds which our visiting bird watchers have submitted! If you are interested in birds have a 
          look through the <Link to="/birds/gallery" onClick={() => {window.scroll(0, 0);}}>bird gallery</Link>, or 
          maybe <Link to="/birds/gallery" onClick={() => {window.scroll(0, 0);}}>submit your own</Link> photos and observations!
        </p>
      </div> 
    </div>
      <PhotoAlbum
        photos={data}
        layout="rows"
        targetRowHeight={150}
        spacing={0}
        padding={2}
        onClick={({ index }) => setIndex(index)}
      />

      <Lightbox
        slides={data}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
  );
};

export default Component;
